import { useContext } from "react";
import { LoadingPage } from "@app";

import { forwardRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { motion } from 'framer-motion';

const BackgroundImage = forwardRef(({ name = "catalogo", src, ...props }, ref) => {
    const { isLoading } = useContext(LoadingPage);

    if (isLoading) {
        return (<></>)
    }

    return (
        <section className="min-h-0 mb-10 h-fit relative">
            <div className="w-full overflow-hidden aspect-[16/9] lg:aspect-[21/10] 2xl:aspect-[21/8]">
                <motion.div
                    initial={{ translateY: '-80%', opacity: 0, }}
                    animate={{ translateY: 0, opacity: 1, transition: { duration: .3, delay: .2, type: "easeInOut" } }}
                    className="relative h-fit w-full">
                    <img src={src} alt="" className="w-full object-cover aspect-[16/9] lg:aspect-[21/10] 2xl:aspect-[21/8] blur-sm scale-105 brightness-[.8]" />
                    <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center ">
                        <h1 className="uppercase font-semibold text-6xl sm:text-[5rem] md:text-[8rem] lg:text-[10rem] xl:text-[14rem] text-white text-shadow text-shadow-x-0 text-shadow-y-0 text-shadow-blur-2 text-shadow-black-500">
                            {name}
                        </h1>
                    </div>
                </motion.div>
            </div>
            <div className="absolute -bottom-10 left-0 w-full flex justify-center items-center">
                <motion.button
                    ref={ref}
                    initial={{ scale: 0, opacity: 0, }}
                    animate={{ scale: 1.25, opacity: 1, transition: { duration: .5, delay: .5, type: "spring" } }}
                    whileTap={{ scale: 1 }}
                    {...props}
                    className="w-[5rem] bg-[#EEE5DE] rounded-full aspect-1 flex justify-center items-center shadow-[0px_2px_8px_#0000003f]">
                    <FontAwesomeIcon icon={faChevronDown} className="text-6xl text-white drop-shadow-[0_-2px_2px_rgba(0,0,0,0.2)] -translate-y-1" />
                </motion.button>
            </div>
        </section>
    )
})

export default BackgroundImage;