const createPath = (path) => {
    let params = { ...path };
    delete params.pathName;

    const pathName = typeof path.pathName === 'string' ? (path.pathName)
        : (path.pathName.map((name) => name).join('/'));

    return [(pathName.slice(-1) === '?' ? (pathName) : (pathName + '?')), (Object.keys(params).map((item) => params[item]).join('&'))].join('')
}

export { createPath };