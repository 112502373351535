import { forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCouch, faHandshakeAngle, faAward, faStarHalfStroke, faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";

import { motion } from "framer-motion";

const right = [
    { icon: faAward, title: "Qualità Garantita", description: "Presso Nicol Casa, ogni pezzo di arredamento è realizzato con materiali selezionati e lavorato con cura per garantire durata e bellezza nel tempo." },
    { icon: faCouch, title: "Vasta Scelta", description: "Trova presso Nicol Casa il pezzo perfetto per il tuo stile tra la nostra ampia selezione di mobili e accessori." },
    { icon: faHandshakeAngle, title: "Assistenza Clienti", description: "Da Nicol Casa, siamo qui per te, pronti a rispondere alle tue domande e a soddisfare le tue esigenze con cortesia, competenza e un servizio personalizzato su misura per te." },
]

const Descriptions = forwardRef(({ ...props }, ref) => {

    return (
        <section className="w-full px-10 2xl:px-40 lg:text-white md:my-20 overflow-x-clip">
            <motion.div
                initial={{ opacity: 0, translateX: '50%' }}
                whileInView={{ opacity: 1, translateX: 0, transition: { delay: .4 } }}
                viewport={{ once: true }}
                className="w-full py-5 min-h-80 lg:bg-[#635B55] grid lg:grid-cols-2  md:px-10 lg:shadow-lg shadow-zinc-400 rounded-sm">
                <div className="flex flex-col justify-start items-center py-10 md:py-20 lg:pl-20 gap-10 lg:text-start text-center">
                    <h4 className="capitalize font-semibold text-lg sm:text-2xl lg:text-3xl">Il nostro impegno per garantire la tua soddisfazione</h4>
                    <p className="md:leading-8 leading-5 md:text-base text-sm">Abbiamo a cuore la qualità e la tua felicità. Con una lunga tradizione di eccellenza, presso Nicol Casa ti offriamo una selezione esclusiva di mobili di alta qualità e un servizio su cui puoi fare affidamento.</p>
                    <div className="w-full grid lg:grid-cols-2 gap-10 lg:gap-20">
                        <div className="flex flex-col gap-1">
                            <div className="flex gap-1 w-full lg:justify-start justify-center items-center text-yellow-500 text-xl">
                                <FontAwesomeIcon icon={faStarSolid} />
                                <FontAwesomeIcon icon={faStarSolid} />
                                <FontAwesomeIcon icon={faStarSolid} />
                                <FontAwesomeIcon icon={faStarSolid} />
                                <FontAwesomeIcon icon={faStarRegular} />
                            </div>
                            <div className="font-semibold lg:text-lg sm:text-sm text-[11px]">
                                Qualità
                            </div>
                        </div>
                        <div className="flex flex-col gap-1">
                            <div className="flex gap-1 w-full lg:justify-start justify-center items-center text-yellow-500 text-xl">
                                <FontAwesomeIcon icon={faStarSolid} />
                                <FontAwesomeIcon icon={faStarSolid} />
                                <FontAwesomeIcon icon={faStarSolid} />
                                <FontAwesomeIcon icon={faStarSolid} />
                                <FontAwesomeIcon icon={faStarHalfStroke} />
                            </div>
                            <div className="font-semibold lg:text-lg sm:text-sm text-[11px]">
                                Soddisfazione del cliente
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-10 flex flex-col just">
                    <h3 className="lg:hidden w-full text-xl sm:text-2xl md:text-3xl font-semibold text-center mb-5">Le nostre garanzie</h3>
                    <div className="flex flex-col justify-between items-start lg:pl-20 h-full lg:gap-0 gap-10">
                        {right.map((textGroup, index) => (
                            <div key={index} className="grid grid-cols-12 shadow-lg rounded-lg px-4 py-5 bg-[#EEE5DE] lg:shadow-none lg:rounded-none lg:px-0 lg:py-0 lg:bg-transparent">
                                <div className="md:col-span-1 col-span-2 flex justify-center lg:items-start items-center">
                                    <div className="flex justify-center lg:items-start items-center lg:pt-1 lg:w-fit lg:h-fit">
                                        <FontAwesomeIcon icon={textGroup.icon} className="text-yellow-500 inline-block w-6 h-6" />
                                    </div>
                                </div>
                                <div className="col-span-10 flex flex-col justify-center items-start gap-1 pl-2">
                                    <h6 className="inline-block text-base lg:text-2xl font-semibold">{textGroup.title}</h6>
                                    <p className="lg:text-sm md:text-sm text-[11px]">{textGroup.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </motion.div>
        </section>
    )
});

export default Descriptions;