import { Fragment, forwardRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Button } from "@components/ui/button"
import { Separator } from "@components/ui/separator"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const setNavPanelOpen = (navPanelOpen) => {
    navPanelOpen.value = !navPanelOpen.value;
}

const SidePanel = forwardRef(({ links, navPanelOpen }, ref) => {
    const location = useLocation();
    const navigate = useNavigate();
    const navigateTo = (url) => {
        setTimeout(() => {
            navigate(url)
        }, 500)
    }

    if (!links) return (<></>)

    return (
        <motion.div className={`z-[90] pointer-events-none flex justify-end items-center xl:hidden bg-transparent fixed w-screen h-screen font-bold transition-all duration-500 ease-in-out ${navPanelOpen.value ? 'opacity-100 translate-x-0' : 'pointer-events-none opacity-0 translate-x-[100%]'}`} ref={ref}>
            <div className={`transition-all pointer-events-auto w-full origin-top-right bg-[#FCF8F3] h-screen py-2 top-0 left-0 flex flex-col justify-start items-center gap-5 relative`}>
                <div className='w-full flex flex-col items-start justify-start font-medium font-satoshi py-10 md:py-5'>
                    {
                        links.map((link, index) => (
                            <Fragment key={link.title}>
                                <div className='w-full h-fit'>
                                    <Button
                                        index={index}
                                        onClick={() => { setNavPanelOpen(navPanelOpen); navigateTo(link.href) }}
                                        className={`
                                            ${links.length - 1 === index ? 'pb-5' : ''} hover:translate-x-5
                                            text-[#635B55] py-2 px-5 transition-all bg-transparent text-md
                                            hover:bg-transparent flex justify-center items-cener gap-5 text-xl
                                        `}>
                                        <FontAwesomeIcon icon={link.icon} />
                                        <span
                                            className={`
                                                ${location.pathname === link.href || (link.href === '/catalogo' && location.pathname.includes('/prodotti')) ?
                                                    'font-bold' : 'font-normal'}
                                            `}>
                                            {link.title}
                                        </span>
                                    </Button>
                                </div>
                                {
                                    links.length - 1 !== index ?
                                        <Separator className="h-[2px] bg-[#635B55] my-5" />
                                        : <></>
                                }

                            </Fragment>
                        ))
                    }

                </div>
            </div>
        </motion.div>
    )
});

export default SidePanel;