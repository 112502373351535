import { useNavigate } from 'react-router-dom'
import { Button } from "@components/ui/button"
import NicolCasaIcon from '@components/logo/NicolCasaIcon'


const NicolCasaLogo = () => {
    const navigate = useNavigate();
    const navigateTo = (url) => {
        setTimeout(() => {
            navigate(url)
        }, 500)
    }

    return (
        <div className="w-fit flex justify-start items-center">
            <Button onClick={() => { navigateTo('/') }} className="-m-1.5 p-1.5 bg-transparent hover:bg-transparent">
                <h1 className="cursor-pointer select-none font-great-vibes transition-all md:text-5xl text-3xl text-black"><NicolCasaIcon animate={true} /></h1>
            </Button>
        </div>
    )
}

export default NicolCasaLogo