import { forwardRef } from "react";

const CategoryButton = forwardRef(({ src, name, ...props }, ref) => {
    return (
        <div
            ref={ref}
            {...props}
            className="relative hover:scale-[1.15] duration-300 transition-all group w-fit cursor-default"
        >
            <img src={src} className="w-[8rem] md:w-[15rem] aspect-[3/4] rounded-sm shadow-md shadow-[#0000003f]" alt="" />
            <div className="absolute bottom-5 left-0 bg-black/20 flex flex-col justify-center items-center w-full h-[3rem]">
                <h6 className="text-white text-sm sm:text-base font-medium first-letter:capitalize transition-all duration-300 group-hover:scale-150 select-none">{name}</h6>
            </div>
        </div>
    )
})

export default CategoryButton;