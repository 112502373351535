const wait = (timeout) => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(), timeout);
    });
};

export const fetchRetry = async (url, init, retries = 3) => {
    let increasingTimeout = 2;

    for (let count = retries; count > 0; count--) {
        try {
            return await fetch(url, { ...init });
        } catch (error) {
            if (error.name !== "AbortError") throw error;
            increasingTimeout = increasingTimeout ^ 2;
            console.warn(
                `fetch failed, retrying in ${increasingTimeout * (10 ^ -3)
                }s, ${count} retries left.`,
            );
        }
        await wait(increasingTimeout);
    }

    return Promise.reject(
        new Error(`Fetch failed after ${retries} retries.`),
    );
};