import { motion } from "framer-motion";
import { useWindowSize } from '@lib/hooks/useWindowSize.js'

import AutoScrollImages from '@components/home/AutoScrollImages';
import PresentationSquare from '@components/home/PresentationSquare';
import PresentationBanners from '@components/home/PresentationBanners';

import { useContext, useEffect, useState } from "react";
import { LoadingPage } from "../../App";


const animation = {
    toLeft: {
        hidden: {
            translateX: '100%',
            opacity: 0
        },
        visible: {
            translateX: 0,
            opacity: 1,
            transition: { type: 'spring', duration: 1.2 }
        }
    },
    toRight: {
        hidden: {
            translateX: '-100%',
            opacity: 0
        },
        visible: {
            translateX: 0,
            opacity: 1,
            transition: { type: 'easeInOut', duration: 1.2, delay: .5 }
        },
    },
    toTop: {
        hidden: {
            translateY: '100%',
            opacity: 0
        },
        visible: {
            translateY: 0,
            opacity: 1,
            transition: {
                translateY: { type: 'spring', duration: 1.2 },
                opacity: { type: 'ease', delay: .2, duration: 1.2 }
            }
        },
    },
}

const carouselImages = {
    first: [
        { src: "https://images.unsplash.com/photo-1503602642458-232111445657?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { src: "https://images.unsplash.com/photo-1599696848652-f0ff23bc911f?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { src: "https://images.unsplash.com/photo-1517705008128-361805f42e86?q=80&w=1987&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { src: "https://images.unsplash.com/photo-1588082255003-1a93643ecf59?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDd8fGFycmVkYW1lbnRvfGVufDB8fDB8fHww" },
        { src: "https://images.unsplash.com/photo-1549187774-b4e9b0445b41?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { src: "https://images.unsplash.com/photo-1582037928769-181f2644ecb7?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { src: "https://images.unsplash.com/photo-1554465967-36697f5d78a1?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { src: "https://images.unsplash.com/photo-1586023492125-27b2c045efd7?q=80&w=1916&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { src: "https://images.unsplash.com/photo-1603483906763-06126bc42f8c?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { src: "https://images.unsplash.com/photo-1668067516409-f3bb960ab4a2?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" }
    ],
    second: [
        { src: "https://images.unsplash.com/photo-1488813340362-2a31b5522ebe?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { src: "https://images.unsplash.com/photo-1540574163026-643ea20ade25?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { src: "https://images.unsplash.com/photo-1502672260266-1c1ef2d93688?q=80&w=1980&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { src: "https://images.unsplash.com/photo-1522771739844-6a9f6d5f14af?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { src: "https://images.unsplash.com/photo-1609081144289-eacc3108cd03?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDN8fHxlbnwwfHx8fHw%3D" },
        { src: "https://images.unsplash.com/photo-1693748961027-756b95c4f396?q=80&w=1906&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { src: "https://images.unsplash.com/photo-1616047006789-b7af5afb8c20?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { src: "https://images.unsplash.com/photo-1616046229478-9901c5536a45?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { src: "https://images.unsplash.com/photo-1616137422495-1e9e46e2aa77?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { src: "https://images.unsplash.com/photo-1583329550487-0fa300a4cd1a?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" }
    ]
}

/* const fetchImg = async (src) => {
    try {
        const response = await fetch(src);

        if (response.ok) {
            return URL.createObjectURL(await response.blob())
        }
        throw new Error(await response.error());
    } catch (e) {
        throw new Error(e);
    }
}

const downloadImages = async (images) => {
    let downloadedImages = [];
    try {
        if (!Array.isArray(images)) throw new Error("No images array passed to arguments");
        for (const img of images) {
            const downloadedImg = await fetchImg(img.src);
            downloadedImages = [...downloadedImages, downloadedImg];
        }
        return [...downloadedImages];
    } catch (e) {
        console.error(e);
    }
} */



export default function Background() {
    const { isLoading, setIsLoading } = useContext(LoadingPage);

    const [isMobile, setIsMobile] = useState(false);
    const [speed, setSpeed] = useState(1);

    useWindowSize((windowWidth) => {
        let isScreenMobile = windowWidth < 768;

        if (!isScreenMobile) {
            setIsMobile(false)
            setSpeed(4)
            return;
        }
        setIsMobile(true);
        setSpeed(1);
    });

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 500)
    })

    if (isLoading) return (<></>)

    return (
        <section className={`transition-all relative min-h-0 md:mt-10 md:mb-8 mt-8 overflow-x-clip`}>
            <motion.div initial="hidden" animate="visible" className="w-screen h-fit relative text-black flex justify-center items-center">
                <motion.div variants={animation.toRight} className="absolute top-0 left-0 bottom-0 w-full flex flex-col items-center justify-start md:justify-center md:pt-0 pt-8 pointer-events-none">
                    <AutoScrollImages
                        speed={speed}
                        images={carouselImages.first}
                    />
                    {isMobile && (
                        <AutoScrollImages
                            speed={speed}
                            direction="forward"
                            images={carouselImages.second}
                        />
                    )}
                </motion.div>
                <PresentationSquare />
            </motion.div>
            <PresentationBanners />
        </section >
    )
}