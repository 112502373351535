import ButtonShare from '@components/product/ButtonShare';
import ProductAttributes from '@components/product/ProductAttributes';
import ProductImages from '@components/product/ProductImages';
import ProductPath from '@components/product/ProductPath';

import { Separator } from '@components/ui/separator'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faWeightHanging } from "@fortawesome/free-solid-svg-icons";

import ReactHtmlParser from 'html-react-parser';
import { motion } from 'framer-motion';
const ButtonCall = () => {
    return (
        <div className="w-full h-fit flex justify-start items-center">
            <motion.a
                href="tel:+393927533470"
                whileTap={{
                    background: 'rgb(22, 163, 74)',
                    color: 'rgb(255, 255, 255)',
                    scale: 1.05,
                }}
                className={`
                    whitespace-nowrap ring-offset-background focus-visible:outline-none
                    focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2
                    disabled:pointer-events-none disabled:opacity-50 h-10 px-4 py-2 flex
                    items-center justify-between rounded-sm text-md font-bold bg-white
                    text-[#635B55] shadow-md shadow-black/20
                `}>
                <FontAwesomeIcon icon={faPhone} />
                <span className="mx-2">Chiama ora</span>
            </motion.a>
        </div>
    )
}

const ProductGrid = ({ product }) => {

    if (!product.value) {
        return (<></>);
    }

    return (
        <div className="w-full grid grid-cols-1 md:gap-5">
            <div className='md:pb-5'>
                <ProductPath product={product} />
            </div>
            <div className='hidden md:block lg:hidden mb-2'>
                <h1 className="md:font-extrabold font-bold text-lg md:text-xl lg:text-2xl xl:text-3xl">{product.value.name}</h1>

                <Separator className="w-full my-2 xl:my-4 h-[4px] bg-[#EEE5DE] rounded-full" />
                <div className="w-full h-fit flex justify-between items-center">
                    <h3 className="md:pl-5 pl-0 font-semibold text-lg md:text-xl lg:text-2xl xl:text-3xl text-green-600">{product.value.price}€</h3>
                    <ButtonShare product={product} />
                </div>
                <Separator className="w-full hidden md:flex my-2 xl:my-4 h-[4px] bg-[#EEE5DE] rounded-full" />
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-12 lg:gap-20 xl:gap-28 aspect-[16/9]">
                <div className="h-fit w-full flex justify-center items-center">
                    <div className="w-[700px] flex flex-col">
                        <div className='w-fit h-fit flex flex-col gap-4'>
                            <ProductImages product={product} />
                        </div>
                        <div className="flex justify-between items-center md:hidden">
                            <div className='py-2 flex flex-col gap-1'>
                                <h1 className="md:font-extrabold font-bold text-lg md:text-xl lg:text-2xl xl:text-3xl">{product.value.name}</h1>
                            </div>
                        </div>
                        <div className="w-full h-fit md:hidden">
                            <Separator className="w-full my-4 h-[4px] bg-[#EEE5DE] rounded-full" />
                            <div className="w-full h-fit flex justify-between items-center">
                                <div className="w-full h-fit flex justify-start items-center">
                                    <h3 className="md:pl-5 pl-0 font-semibold text-2xl md:text-3xl text-green-600">{product.value.price}€</h3>
                                </div>
                                <ButtonShare product={product} />
                            </div>
                        </div>
                        <div className='lg:hidden'>
                            <Separator className="w-full my-4 h-[4px] bg-[#EEE5DE] rounded-full" />
                            <ButtonCall />
                            <Separator className="w-full my-4 h-[4px] md:hidden bg-[#EEE5DE] rounded-full" />
                        </div>
                    </div>
                </div>
                <div className="w-full h-full flex flex-col gap-0 relative">
                    <div className="w-full h-fit lg:flex hidden justify-between items-center">
                        <h1 className="font-extrabold text-lg md:text-xl lg:text-2xl xl:text-3xl">{product.value.name}</h1>
                    </div>

                    <div className='lg:block hidden'>
                        <Separator className="w-full my-2 xl:my-4 h-[4px] bg-[#EEE5DE] rounded-full" />
                        <div className="w-full h-fit lg:flex hidden justify-between items-center">
                            <div className="w-full h-fit flex justify-start items-center">
                                <h3 className="md:pl-5 pl-0 font-semibold text-lg md:text-xl lg:text-2xl xl:text-3xl text-green-600">{product.value.price}€</h3>
                            </div>
                            <ButtonShare product={product} />
                        </div>

                        <Separator className="w-full hidden md:flex my-2 xl:my-4 h-[4px] bg-[#EEE5DE] rounded-full" />
                    </div>

                    <div className="w-full h-fit hidden md:flex flex-col justify-start items-start gap-2 text-[#464646]">
                        <h3 className="font-extrabold text-base first-letter:capitalize">
                            descrizione:
                        </h3>
                        <div>
                            <h3 className="col-span-11 font-normal text-left md:text-[12px] lg:text-sm leading-5">
                                {ReactHtmlParser(product.value.description).filter(item => typeof item === "object").map((item) => (
                                    item.props.children
                                ))}
                            </h3>
                        </div>
                        <h3 className="font-extrabold text-base first-letter:capitalize">Dimensioni</h3>
                        <div className='flex justify-start items-center gap-3 font-normal md:text-[12px] lg:text-sm leading-5'>
                            <p>
                                {(() => {
                                    const { length, width, height } = product.value.dimensions;
                                    let dimensions = "";
                                    if (length && length !== "") dimensions += `${length} x `;
                                    if (width && width !== "" && height && height !== "") dimensions += `${width} x ${height}`;
                                    return dimensions
                                })()}
                            </p>
                        </div>

                        {product.value.weight !== "" && (
                            <div className="w-fit h-fit flex justify-center items-start flex-col">
                                <h3 className="font-extrabold text-base first-letter:capitalize">Peso</h3>
                                <div className='flex justify-start items-center gap-3 font-normal md:text-[12px] lg:text-sm leading-5'>
                                    <FontAwesomeIcon icon={faWeightHanging} />
                                    <p>{product.value.weight}</p>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="w-full h-fit">
                        <div className='hidden lg:block'>
                            <Separator className="w-full my-4 h-[4px] bg-[#EEE5DE] rounded-full" />
                            <h6 className='font-bold'>Chiama ora: <a className='font-medium transition-colors hover:text-green-400 text-green-600' href="tel:+393927533470">+39 392 753 3470</a></h6>
                        </div>
                    </div>
                    <Separator className="w-full my-4 h-[4px] md:block hidden bg-[#EEE5DE] rounded-full" />
                    <ProductAttributes product={product} />
                </div>
            </div>
        </div>
    );
}

export default ProductGrid;