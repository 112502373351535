import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import NicolCasaIcon from '@components/logo/NicolCasaIcon'
import { Fragment } from 'react';


const footerCols = [
    {
        title: 'menu', row: [
            {
                text: 'Home',
                href: '/',
                type: 'navigate'
            },
            {
                text: 'Catalogo',
                href: '/catalogo',
                type: 'navigate'
            },
            /*             {
                            text: 'Chi siamo',
                            href: '/chi-siamo',
                            type: 'navigate'
                        }, */
            {
                text: 'Contattaci',
                href: '/contatti',
                type: 'navigate'
            }
        ]
    },

    {
        title: 'social', type: 'navigate', row: [
            {
                text: 'Instagram',
                href: 'https://www.instagram.com/nicolcasagranbazar/',
                type: 'link'
            },
            {
                text: 'Facebook',
                href: "https://www.facebook.com/people/Nicol-Casa-Gran-Bazar/100063640967730/",
                type: 'link'
            }
        ]
    },
    {
        title: 'contatti', type: 'navigate', row: [
            {
                text: 'Corso Roma 228, Massafra, TA',
                type: 'text'
            },
            {
                text: 'info@nicolcasa.it',
                href: "mailto:info@nicolcasa.it",
                type: 'link'
            },
            {
                text: '+39 392 753 3470',
                href: "tel:+393927533470",
                type: 'link'
            }
        ]
    },
]

const makeBody = (navigate) => {
    return footerCols.map((col, colKey) => (
        <div key={colKey} className={`text-[#0A142F] flex lg:flex-row justify-center gap-20 ${colKey === footerCols.length - 1 ? "flex-col" : ""}  mb-20 xl:mb-0`}>
            <div className='w-full flex flex-col lg:items-start items-center justify-start'>
                <h6 className="mb-4 text-sm flex items-center justify-center uppercase md:justify-start">
                    <span className="font-extrabold">{col.title}</span>
                </h6>
                {col.row.map((row, rowKey) => {
                    if (row.type === "text") {
                        return (
                            <p key={rowKey} className="mb-4 text-sm">
                                {row.icon ? <FontAwesomeIcon icon={row.icon} className='mr-3 h-5 w-5 pr-1' /> : <></>}
                                <span>{row.text}</span>
                            </p>
                        )
                    }
                    if (row.type === "navigate") {
                        return (
                            <p key={rowKey} className="mb-4 text-sm">
                                <button
                                    onClick={() => navigate(row.href)}
                                    className="bg-transparent border-none outline-none transition-all hover:scale-110">
                                    {row.icon ? <FontAwesomeIcon icon={row.icon} className='mr-3 h-5 w-5 pr-1' /> : <></>}
                                    <span>{row.text}</span>
                                </button>
                            </p>
                        )
                    }
                    if (row.type === "link") {
                        return (
                            <p key={rowKey} className="mb-4 text-sm flex items-center justify-center md:justify-start transition-all hover:scale-110">
                                {row.icon ? <FontAwesomeIcon icon={row.icon} className='mr-3 h-5 w-5 pr-1' /> : <></>}
                                <a
                                    className="font-semibold transition-colors hover:text-blue-400 text-blue-500" href={row.href}>
                                    <span>{row.text}</span>
                                </a>
                            </p>
                        )
                    }

                    return (<Fragment key={rowKey}></Fragment>)
                })}
            </div>
        </div>
    ))
}

export default function Footer() {
    const navigate = useNavigate();

    return (
        <footer className="relative font-semibold bg-white text-center text-neutral-600 shadow-xl lg:text-left pt-32 z-[200]">
            <div className="mx-6 py-10 text-center md:text-left">
                <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                    <div className='flex justify-center'>
                        <div className='flex flex-col max-w-[16rem]'>
                            <div className='flex justify-start items-center gap-10'>
                                <h5 className="mb-4 text-sm flex items-center justify-center uppercase md:justify-start">
                                    <NicolCasaIcon animate={false} />
                                </h5>
                                <h6 className='text-lg text-[#0A142F] font-semibold'>NICOLCASA</h6>
                            </div>
                            <div className='pl-2 font-medium text-black text-[12px]'>
                                <p>Punto commerciale di arredamenti per la casa, situato a Massafra</p>
                            </div>
                        </div>
                    </div>
                    <div className="grid-1 grid lg:grid-cols-2 xl:grid-cols-4 text-neutral-600">
                        {makeBody(navigate)}
                        <div className='flex justify-center items-center'>
                            <button
                                className='text-white bg-blue-500 w-16 aspect-1 rounded-full shadow-md shadow-[#00000047] text-2xl transition-all hover:scale-125'
                                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                            >
                                <FontAwesomeIcon icon={faArrowUp} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex flex-col justify-center items-center w-full bg-zinc-300 text-[#0A142F] p-8 gap-3'>
                <div className="text-center flex justify-center items-center gap-5">
                    <div>
                        <span>P.IVA: </span>
                        <span className="font-semibold">03205100732</span>
                    </div>
                </div>
                <div className="text-left flex justify-center items-center gap-5">
                    <a href="https://www.iubenda.com/privacy-policy/92622801" className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe" target="_blank" rel="noreferrer" title="Privacy Policy ">Privacy Policy</a>
                    <a href="https://www.iubenda.com/privacy-policy/92622801/cookie-policy" className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe" target="_blank" rel="noreferrer" title="Cookie Policy ">Cookie Policy</a>
                </div>
            </div>
        </footer>
    )
}