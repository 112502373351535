import { forwardRef } from 'react'
import { computed } from "@preact/signals-react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Button } from "@components/ui/button"



const PanelButton = forwardRef(({ exit, ...props }, ref) => {
    const exitTrigger = computed(() => exit.value);

    return (
        <motion.div
            whileTap={{ scale: 0.8 }}
            className="w-fit shadow-md rounded-full xl:hidden flex justify-end items-center">
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1.2 } }}>
                <Button
                    ref={ref}
                    onClick={() => { props.onClick() }}
                    className={`
                        bg-white rounded-full  sinline-flex items-center justify-center transition-all rotate-0
                        p-0 w-10 aspect-[1/1] hover:bg-white
                        ${exitTrigger.value ? 'rotate-180 text-[#BB0A21]' : 'rotate-0 text-[#635B55]'}
                    `}>
                    <span className="sr-only">Open main menu</span>
                    <FontAwesomeIcon icon={exitTrigger.value ? faXmark : faBars} className="h-5 aspect-1" aria-hidden="true" />
                </Button>
            </motion.div>
        </motion.div>
    )
});

export default PanelButton;