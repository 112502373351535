import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@components/ui/carousel";

const RelatedProducts = ({ product }) => {
    const navigate = useNavigate();

    const displayContent = () => (
        product.value.related_products.map((p, index) => {
            return (
                <CarouselItem key={index} className="basis-1/3 lg:basis-1/4 px-2 pl-2">
                    <motion.div
                        onClick={() => navigate(`/prodotti/${p.id}`)}
                        whileTap={{ scale: 0.9, transition: { duration: .3 } }}
                        className="p-0 md:p-3 col-span-1 w-full h-full flex flex-col bg-transparent text-black overflow-hidden group"
                    >
                        <div className='w-fit h-fit relative transition-all duration-500 group-hover:scale-105 shadow-md rounded-md overflow-clip'>
                            <img src={p.images[0].src} className="duration-100 w-full aspect-1 object-center object-cover bg-white " alt="" />
                        </div>
                        <div className="w-full flex flex-col justify-center items-start gap-2 py-3 transition-all duration-500 group-hover:translate-y-3">
                            <div>
                                <h1 className="flex justify-start items-center h-full lg:text-xl md:text-xl sm:text-[18px] text-sm font-semibold pr-5 ">{p.name}</h1>
                            </div>
                            <div>
                                <p className="flex justify-start items-center h-full lg:text-xl md:text-lg sm:text-[18px] text-sm  font-semibold text-green-600 pr-5 ">{p.price}€</p>
                            </div>
                        </div>
                    </motion.div>
                </CarouselItem>
            )
        })
    )

    return (
        <div className="flex flex-col justify-center gap-5 items-center h-fit w-full lg:mb-20 overflow-hidden lg:overflow-visible">
            <div className="w-[95%] flex justify-start items-center font-medium xl:text-4xl lg:text-3xl md:text-2xl text-xl text-black">
                <h1 className="w-full p-5 px-0 font-bold">Altri prodotti che potrebbero piacerti</h1>
            </div>
            <Carousel className="w-full py-5">
                <CarouselContent className="-ml-1 pt-2 pb-6">
                    {product.value ? displayContent() : (<></>)}
                </CarouselContent>
                <CarouselPrevious className="md:flex hidden bg-white scale-150" />
                <CarouselNext className="md:flex hidden bg-white scale-150" />
            </Carousel>
        </div>
    )
}

export default RelatedProducts;