import { useEffect, useRef } from 'react'
import { useSignal } from "@preact/signals-react";
import { motion, useAnimate, useScroll, useMotionValueEvent } from "framer-motion";


import PanelButton from '@components/header/PanelButton'
import SearchBar from '@components/header/SearchBar'
import MobileSearchButton from '@components/header/MobileSearchButton'
import MobileSearchBar from '@components/header/MobileSearchBar'
import HorizontalPanelLinks from '@components/header/HorizontalPanelLinks'
import SidePanel from '@components/header/SidePanel'

import Logo from '@components/logo/Logo'
import { faHouse, /* faAddressCard, */ faList, faAddressBook } from '@fortawesome/free-solid-svg-icons'

const links = [
    { icon: faHouse, title: 'Home', href: "/" },
    { icon: faList, title: 'Catalogo', href: "/catalogo" },
    /* { icon: faAddressCard, title: 'Chi Siamo', href: "/chi-siamo" }, */
    { icon: faAddressBook, title: 'Contattaci', href: "/contatti" }
];

export default function Header() {
    const mobileSearchOpen = useSignal(false);
    const navPanelOpen = useSignal(false);
    const headerHidden = useSignal(false);
    const headerInitial = useSignal(true);

    const buttonOpenPanelRef = useRef(null);
    const mobileSearchRef = useRef(null);
    const panelRef = useRef(null);
    const headerRef = useRef(null);
    const [navRef, animateNav] = useAnimate();

    const { scrollY } = useScroll();

    const setMobileSearchOpen = async (value) => {
        if (value === false || value === true) {
            await animateNav(navRef.current, { opacity: 0 }, { duration: 0.1, delay: 0 });
            mobileSearchOpen.value = value;
            await animateNav(navRef.current, { opacity: 1 }, { duration: 0.1, delay: 0 });
            if (value === true) mobileSearchRef.current.focus();
        }
    }
    useMotionValueEvent(scrollY, "change", (latest) => {
        const previous = scrollY.getPrevious();

        if (latest > 100) {
            headerInitial.value = false;
        } else {
            headerInitial.value = true;
        }

        if (latest > previous && latest > 0 && !navPanelOpen.peek()) {
            headerHidden.value = true;
            return;
        }
        headerHidden.value = false;
    });

    useEffect(() => {
        const handler = (e) => {
            if (!buttonOpenPanelRef.current.contains(e.target) && !panelRef.current.contains(e.target)) {
                navPanelOpen.value = false;
            }

            if (!mobileSearchRef.current.contains(e.target) && mobileSearchOpen.peek() === true) {
                setMobileSearchOpen(false);
            }
        }

        document.addEventListener('mousedown', handler);

        return () => {
            document.removeEventListener('mousedown', handler);
        };
    })

    return (
        <>
            <motion.header
                ref={headerRef}
                className={`
                    ${navPanelOpen.value || headerInitial.value === false ? "bg-[#FCF8F3]" : "bg-transparent"}
                    ${headerInitial.value === false && !navPanelOpen.value ? "shadow-md" : ""}
                    ${headerHidden.value ? 'translate-y-[-100%]' : 'translate-y-0'} z-50
                    text-black sticky top-0 left-0 w-full transition-all duration-500 ease
                `}>

                <motion.nav
                    ref={navRef}
                    className={`flex w-full items-center justify-between md:h-28 h-14 p-6 py-2 md:py-2 px-8`}
                    aria-label="Global">

                    <div className={`w-full grid grid-cols-2 ${mobileSearchOpen.value ? "hidden" : ""}`}>
                        <div className="flex gap-10 pr-40">
                            <Logo />
                            <SearchBar />
                        </div>
                        <div className='flex justify-end items-center'>
                            <HorizontalPanelLinks links={links} />
                        </div>
                    </div>

                    <div className={`${!mobileSearchOpen.value ? "hidden" : ""} w-full`} >
                        <MobileSearchBar ref={mobileSearchRef} />
                    </div>

                    <div className={`${mobileSearchOpen.value ? "hidden" : ""} flex gap-3`}>
                        <MobileSearchButton onClick={() => setMobileSearchOpen(true)} />
                        <PanelButton
                            ref={buttonOpenPanelRef}
                            onClick={() => navPanelOpen.value = !navPanelOpen.peek()}
                            exit={navPanelOpen}
                        />
                    </div>
                </motion.nav>
            </motion.header >
            <SidePanel links={links} navPanelOpen={navPanelOpen} ref={panelRef} />
        </>
    )
}