import { useEffect, useRef, forwardRef } from "react";
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom';
import { useSignal } from "@preact/signals-react";

import { motion } from "framer-motion";
//import { Input } from "@components/ui/input";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBar = forwardRef(({ ...props }, ref) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const query = useSignal('');
    const isOnFocus = useSignal(false);
    const labelRef = useRef(null);

    const handleSearch = (e) => {
        query.value = e.target.value;
    }

    const handleSubmitSearch = () => {
        isOnFocus.value = false;
        document.activeElement.blur();
        if (query.peek() && query.peek().replace(/^\s+|\s+$/gm, '') !== '') {
            if (query.value !== '' && query.value) {
                navigate({
                    pathname: "/prodotti",
                    search: `${createSearchParams({ search: query.value })}`
                });
            }
        }
    }

    useEffect(() => {
        query.value = '';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams])

    useEffect(() => {
        const onFocus = (e) => {
            if (!labelRef.current.contains(e.target)) {
                isOnFocus.value = false;
                return;
            }
            isOnFocus.value = true;
        }

        document.addEventListener('mousedown', onFocus);

        return () => {
            document.removeEventListener('mousedown', onFocus);
        };
    })
    return (
        <label
            ref={labelRef}
            className={`
                hidden xl:flex w-full max-w-[25rem] justify-start items-center bg-white rounded-sm shadow-md
                overflow-hidden transition-all outline outline-2 ${isOnFocus.value ? 'outline-[#635B55]' : 'outline-transparent'}
            `}>
            <FontAwesomeIcon icon={faSearch} className="px-5 text-zinc-400" />
            <motion.input
                {...props}
                ref={ref}
                onChange={handleSearch}


                onKeyDown={(e) => e.key === 'Enter' ? handleSubmitSearch() : null}
                placeholder="Ricerca"
                className="py-3 w-full px-5 placeholder-zinc-400 focus:outline-none"
            />
        </label>
    )
})

export default SearchBar;