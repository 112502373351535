import { forwardRef } from "react";
import { useNavigate, createSearchParams } from 'react-router-dom';
import { motion } from "framer-motion";
import { useSignal } from "@preact/signals-react";
//import { Input } from "@components/ui/input";

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faSearch } from '@fortawesome/free-solid-svg-icons';

const MobileSearchBar = forwardRef(({ ...props }, ref) => {
    const navigate = useNavigate();
    const query = useSignal('');

    const handleSearch = (e) => {
        query.value = e.target.value;
    }

    const handleSubmitSearch = () => {
        document.activeElement.blur();
        if (query.peek() && query.peek().replace(/^\s+|\s+$/gm, '') !== '') {
            if (query.value !== '' && query.value) {
                navigate({
                    pathname: "/prodotti",
                    search: `${createSearchParams({ search: query.value })}`
                });
            }
        }
    }

    return (
        <motion.input
            {...props}
            ref={ref}
            onChange={handleSearch}
            onKeyDown={(e) => e.key === 'Enter' ? handleSubmitSearch() : null}
            placeholder="Ricerca"
            className="w-full py-2 md:py-4 px-5 rounded-full transition-colors shadow-md flex xl:hidden outline outline-2 outline-[#635B55]"
        />
    )
})

export default MobileSearchBar;