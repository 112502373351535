import { forwardRef } from "react";
import { motion } from "framer-motion";

const images = [
    { src: "https://images.unsplash.com/photo-1560449752-ac541afdd6b5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { src: "https://images.unsplash.com/photo-1511974212900-b42a18e19eb8?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { src: "https://images.unsplash.com/photo-1537212013010-de9c76f8f0ae?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MjN8ODc3Mzc4NHx8ZW58MHx8fHx8" },
    { src: "https://images.unsplash.com/photo-1504977402025-84285fea814b?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { src: "https://images.unsplash.com/photo-1558882511-807406a777fe?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTN8NzUxMTA3NTR8fGVufDB8fHx8fA%3D%3D" },
    { src: "https://images.unsplash.com/photo-1596900749995-57cec6ddb861?q=80&w=1876&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { src: "https://images.unsplash.com/photo-1595515106883-5d5da3043540?q=80&w=1917&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { src: "https://images.unsplash.com/photo-1572297259518-0974576b6738?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { src: "https://images.unsplash.com/photo-1649083048876-b437770dfce5?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { src: "https://images.unsplash.com/photo-1639113730940-64416c3b2a01?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { src: "https://images.unsplash.com/photo-1613252036716-e1efc9788e5b?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { src: "https://images.unsplash.com/photo-1615919879320-7ffe0898363f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTEwfDc1MTEwNzU0fHxlbnwwfHx8fHw%3D" },
    { src: "https://images.unsplash.com/photo-1618220179428-22790b461013?q=80&w=2127&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { src: "https://images.unsplash.com/photo-1616137148650-4aa14651e02b?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { src: "https://images.unsplash.com/photo-1558645574-1b4b00e2c74b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MjYyfDc1MTEwNzU0fHxlbnwwfHx8fHw%3D" },
];

const animateImg = {
    hidden: {
        opacity: 0,
        translateY: "20%",
    },
    visible: (index) => ({
        opacity: 1,
        translateY: 0,
        transition: {
            delay: 0.05 * index
        }
    })
}
const Gallery = forwardRef(() => {
    return (
        <section className="min-h-80 md:my-20 lg:my-40 px-10 md:px-40">
            <div className="flex flex-col justify-center items-center gap-20">
                <motion.div
                    initial={{ opacity: 0, translateX: '-50%' }}
                    whileInView={{ opacity: 1, translateX: 0, transition: { delay: .4 } }}
                    viewport={{ once: true }}
                    className="flex flex-col justify-start items-center text-center gap-5">
                    <h3 className="first-letter:capitalize text-purple-800 font-semibold text-xl">Controlla subito la nostra collezione</h3>
                    <h4 className="capitalize font-semibold text-4xl">La nostra galleria di mobili</h4>
                </motion.div>
                <div className="gap-5 lg:gap-8 columns-2 lg:columns-3 xl:columns-4 [&>img:not(:first-child)]:mt-5 lg:[&>img:not(:first-child)]:mt-8">
                    {images.map((img, index) => (
                        <motion.img
                            key={index}
                            fetchpriority="medium"
                            className="rounded-sm shadow-md shadow-zinc-400"
                            src={img.src}
                            alt=""
                            variants={animateImg}
                            initial="hidden"
                            whileInView="visible"
                            custom={index}
                            viewport={{
                                once: true,
                            }} />
                    ))}
                </div>
            </div>
        </section>
    )
});

export default Gallery;