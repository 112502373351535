import ReactHtmlParser from 'html-react-parser';
import { Separator } from '@components/ui/separator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWeightHanging } from '@fortawesome/free-solid-svg-icons';

import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@components/ui/tabs"

const ListAttributes = ({ product }) => {
    return (
        <div className='flex flex-col gap-3 mt-10'>
            <div className="w-fit h-fit flex justify-center items-start flex-col">
                <h3 className="font-extrabold text-base text-black first-letter:capitalize">Dimensioni</h3>
                <div className='flex justify-start items-center gap-3 text-sm md:text-base lg:text-xl'>
                    <p>{product.value.dimensions["length"]} x {product.value.dimensions["width"]} x {product.value.dimensions["height"]}</p>
                </div>
            </div>
            {product.value.weight !== " Kg" ? (
                <div className="w-fit h-fit flex justify-center items-start flex-col">
                    <h3 className="font-extrabold text-base text-black first-letter:capitalize">Peso</h3>
                    <div className='flex justify-start items-center gap-3 text-xl'>
                        <FontAwesomeIcon icon={faWeightHanging} />
                        <p>{product.value.weight}</p>
                    </div>
                </div>
            ) : (<></>)}
            {product.value.attributes.map((attr, index) => {
                return (
                    <div key={index} className="w-full h-fit flex justify-center items-start flex-col">
                        <h3 className="font-extrabold text-base text-black first-letter:capitalize">{attr.name}</h3>
                        <div className="flex justify-start items-center gap-2">
                            {
                                attr.options.map((opt, index) => {
                                    const name = typeof opt === "string" ? opt : opt.name;
                                    return (
                                        <div key={index} className=''>
                                            {name + (index < attr.options.length - 1 ? "," : "")}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const ProductTabs = ({ product }) => {
    const tabs = [
        { title: 'Descrizione', triggerValue: 'description', value: product.value?.description ? ReactHtmlParser(product.value.description) : "Nessuna informazione disponibile." },
        { title: 'Caratteristiche', triggerValue: 'caratteristiche', value: <ListAttributes product={product} /> },
    ];
    const defaultValue = tabs[0].triggerValue;

    if (!product.value) {
        return (<></>);
    }

    return (
        <div className="flex md:hidden flex-col w-full h-fit justify-center items-center">
            <Separator className="w-full my-4 h-[4px] bg-[#EEE5DE] rounded-full" />

            <Tabs defaultValue={defaultValue} className="w-full transition-all bg-gradient-to-b from-transparent from-[3%]  via-[#EEE5DE] via-[1%] to-[#EEE5DE] to-90%">
                <TabsList className="grid w-full grid-cols-2 p-0 overflow-clip bg-[#EEE5DE] rounded-t-sm shadow-none">
                    {tabs.map((trigger) => (
                        <TabsTrigger
                            key={trigger.triggerValue}
                            value={trigger.triggerValue}
                            className={`
                            font-medium md:text-xl text-[18px] data-[state=active]:text-black text-black
                            data-[state=active]:shadow-[0px_0px_16px_#0000004f] *:ring-0 bg-white
                            data-[state=active]:bg-[#EEE5DE] data-[state=active]:z-30 *:p-0
                        `}
                        >
                            {trigger.title}
                        </TabsTrigger>
                    ))}
                </TabsList>
                {
                    tabs.map((tab) =>
                        <TabsContent key={tab.triggerValue} value={tab.triggerValue} className="p-[5%] md:text-lg my-5 text-[18px] w-full rounded-md outline-zinc-400 min-h-[20vh] z-30">
                            <div className="flex flex-col justify-between h-full">
                                <div className="w-full text-sm lg:text-base px-2 text-start max-h-[35rem] h-[35rem] overflow-y-scroll">
                                    <h1 className='text-sm font-bold mb-2'>
                                        {tab.title}:
                                    </h1>
                                    {tab.value}
                                </div>
                            </div>
                        </TabsContent>
                    )
                }
            </Tabs>
        </div>
    );
}

export default ProductTabs;