import { useEffect } from 'react';

const useWindowSize = (handler) => {
    useEffect(() => {
        handler(window.innerWidth);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.addEventListener('resize', () => { handler(window.innerWidth) });
        return () => {
            window.removeEventListener('resize', () => { handler() });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handler]);
}

export { useWindowSize }

