import { useRef } from 'react';
import { Input } from '@components/ui/input';
import { Button } from '@components/ui/button';
import { Separator } from '@components/ui/separator';
import { Toaster } from "@components/ui/sonner"
import { toast } from "sonner"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faCopy, faShare, faClipboard } from "@fortawesome/free-solid-svg-icons";
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
} from "@components/ui/drawer"


import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";

import {
    faFacebook,
    faLinkedin,
    faPinterest,
    faReddit,
    faTelegram,
    faTumblr,
    faXTwitter,
    faSquareWhatsapp
} from "@fortawesome/free-brands-svg-icons";

const ReactShare = [
    { component: EmailShareButton, icon: faEnvelope, color: 'text-black' },
    { component: FacebookShareButton, icon: faFacebook, color: 'text-black' },
    { component: LinkedinShareButton, icon: faLinkedin, color: 'text-black' },
    { component: PinterestShareButton, icon: faPinterest, color: 'text-black' },
    { component: RedditShareButton, icon: faReddit, color: 'text-black' },
    { component: TelegramShareButton, icon: faTelegram, color: 'text-black' },
    { component: TumblrShareButton, icon: faTumblr, color: 'text-black' },
    { component: TwitterShareButton, icon: faXTwitter, color: 'text-black' },
    { component: WhatsappShareButton, icon: faSquareWhatsapp, color: 'text-black' }
];

const ProductShare = ({ product }) => {
    const copyButtonRef = useRef(null);

    const copyToClipboard = async (e) => {
        copyButtonRef.current.select();
        navigator.clipboard.writeText(window.location.href);
        toast(
            <div className='w-full flex justify-between items-center gap-2 bg-white'>
                <span>URL copiato negli appunti</span>
                <FontAwesomeIcon icon={faClipboard} />
            </div>
        )
    }

    const SocialButtons = () => {
        return (
            <div className="w-full flex justify-center items-center gap-2 text-3xl bg-white">
                {ReactShare.map((share, key) => {
                    const ShareComponent = share.component;
                    return (
                        <ShareComponent key={key} url={window.location.href} quote={product.value.name} >
                            <FontAwesomeIcon className={share.color} icon={share.icon} />
                        </ShareComponent>
                    );
                })}
            </div>
        )
    }
    return (
        <Drawer>
            <DrawerTrigger asChild>
                <button variant="outline" className={`
                    text-full lg:w-[50px] w-[40px] aspect-1 hover:text-white text-[#635B55] rounded-full text-lg font-bold bg-white transition-all
                    hover:scale-[0.93] hover:bg-blue-600 shadow-md
                `}>
                    <FontAwesomeIcon icon={faShare} />
                </button>
            </DrawerTrigger>
            <DrawerContent className="bg-white">
                <div className="mx-auto mt-4 h-2 w-[100px] rounded-full bg-muted" />
                <div className="mx-auto w-full max-w-sm bg-white">
                    <DrawerHeader className="text-left bg-white">
                        <DrawerTitle>Condividi questo articolo</DrawerTitle>
                        <DrawerDescription>Seleziona l'opzione di condivisione desiderata.</DrawerDescription>
                    </DrawerHeader>
                    <SocialButtons />
                    <div className="p-4 pb-0 flex justify-center items-center gap-2">
                        <Input ref={copyButtonRef} className="transition-all bg-white" readOnly={true} value={window.location.href} />
                        <Button onClick={copyToClipboard} className="text-xl"><FontAwesomeIcon icon={faCopy} /></Button>
                    </div>
                    <Toaster />
                    <Separator className="mt-5" />
                    <DrawerFooter className="pb-28 bg-white">
                        <DrawerClose asChild>
                            <Button variant="outline" className="bg-white">Annulla</Button>
                        </DrawerClose>
                    </DrawerFooter>
                </div>
            </DrawerContent>
        </Drawer>
    )
}

export default ProductShare;