import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { batch, useSignal } from '@preact/signals-react';

import { useScroll, useMotionValueEvent, motion } from 'framer-motion'

import { createPath } from '@lib/createPath'
import { fetchRetry } from "@lib/fetchRetry";
import { Filter, BadgeEuro, Armchair, Palette, Pencil } from 'lucide-react';

import { Checkbox } from "@components/ui/checkbox"
import { Label } from '@components/ui/label'

import { useWindowSize } from '@lib/hooks/useWindowSize.js'

import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@components/ui/accordion"

import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
} from "@components/ui/drawer"

import { Button } from "@components/ui/button"
import { Toggle } from "@components/ui/toggle"
import { Separator } from "@components/ui/separator"

const parseAttribute = (attribute) => {
    const value = attribute.range ? attribute.range.join('-') : attribute.name;
    let checkboxID, text;

    if (attribute.range) {
        checkboxID = 'range_' + (attribute.range[0]);
        text = (attribute.range[0]);
        if (attribute.range[1]) {
            checkboxID += `-${attribute.range[1]}`;
            text = `${text} - ${attribute.range[1]}`;
        } else {
            checkboxID += '+';
            text = `${text} +`;
        }
        return { checkboxID, text, value };
    }

    checkboxID = `${attribute.name}_${attribute.id}`;
    text = attribute.name;

    return { checkboxID, text, value };
}

const filterIcons = {
    prezzo: <BadgeEuro />,
    colore: <Palette />,
    materiale: <Pencil />,
    stile: <Armchair />,
}

const apiURL = process.env.REACT_APP_API_URL;
const Filters = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { scrollY } = useScroll();

    const [appliedFilters, setAppliedFilters] = useState();
    const activatedPadding = useSignal();
    const accordionValues = useSignal([]);
    const filters = useSignal([]);
    const isMobile = useSignal(false);

    const filterContainerRef = useRef(null);


    const setAccordionValues = (data) => {
        batch(() => {
            data.forEach((filter) => (
                filter.values.forEach(({ name }) => {
                    const itemValue = `item-${name}`;
                    accordionValues.value = [...accordionValues.peek(), itemValue]
                })
            ));
        })
    }

    const applyFilter = (type, term) => {
        setAppliedFilters((prevState = {}) => {
            const filters = { ...prevState };
            if (!filters[type]) {
                filters[type] = [term]
                return filters;
            }
            const index = filters[type].indexOf(term);

            if (index === -1) {
                filters[type].push(term)
                return filters;
            }

            filters[type].splice(index, 1);
            if (filters[type].length < 1) {
                delete filters[type];
            }
            return filters;
        })
    }

    const updateSearchParams = () => {
        const search = {}
        searchParams.forEach((value, key) => {
            if (key === "search" || key === "category") {
                search[key] = createSearchParams({ [key]: value })
            }
        });

        const path = createPath({
            pathName: '/prodotti',
            ...search,
            ...Object.keys(appliedFilters).reduce((acc, type) => {
                const filter = appliedFilters[type];
                acc[type] = createSearchParams({ [type]: filter.join('|') });

                return acc;
            }, {})
        })

        navigate(path)
    }

    const displayFilters = () => (
        <>
            <motion.h3
                initial={!isMobile.value && { translateX: '-50%', opacity: 0 }}
                animate={!isMobile.value && { translateX: 0, opacity: 1, transition: { duration: .8, type: "spring" } }}
                className="font-bold text-2xl mb-8 xl:block hidden"
            >
                <span>Filtri</span>
            </motion.h3>
            <Accordion type="multiple" defaultValue={accordionValues.value} className="w-full">
                {filters.value?.map((filter) => (
                    filter.values.map(({ childs, name }, index) => {
                        const itemValue = `item-${name}`;
                        return (
                            <motion.div
                                key={index}
                                initial={!isMobile.value && { translateX: '-50%', opacity: 0 }}
                                animate={!isMobile.value && { translateX: 0, opacity: 1, transition: { duration: .8, type: "spring" } }}
                            >
                                <AccordionItem value={itemValue}>
                                    <AccordionTrigger className="hover:no-underline font-bold py-1">
                                        <div className='flex gap-1'>
                                            {filterIcons[name]}
                                            <span className='first-letter:capitalize pl-1'>{name}</span>
                                        </div>
                                    </AccordionTrigger>
                                    <AccordionContent className="flex flex-wrap gap-1 pl-1 pr-10 py-1">
                                        {childs?.map((item, index) => {
                                            const { text, checkboxID, value } = parseAttribute(item);
                                            const verifyChecked = () => {
                                                if (!appliedFilters[filter.type]) {
                                                    return false;
                                                }

                                                if (appliedFilters[filter.type].indexOf(value) < 0) {
                                                    return false;
                                                }
                                                return true;
                                            }

                                            if (isMobile.value) {
                                                return (
                                                    <Toggle
                                                        key={index}
                                                        id={checkboxID}
                                                        pressed={verifyChecked()}
                                                        onPressedChange={() => applyFilter(filter.type, value)}
                                                        className={`
                                                            py-1 px-4 border-[1px] h-8 border-zinc-400 rounded-md bg-transparent
                                                            data-[state=on]:border-transparent hover:bg-white hover:text-black
                                                            hover:scale-105 mr-1 mt-1 transition-all bg-white font-light
                                                            data-[state=on]:bg-primary data-[state=on]:text-white
                                                        `}
                                                    >
                                                        <span>
                                                            {text}
                                                        </span>
                                                    </Toggle>
                                                )
                                            }

                                            return (
                                                <div key={index} className='flex justify-between w-full'>
                                                    <Label>{text}</Label>
                                                    <Checkbox
                                                        id={checkboxID}
                                                        checked={verifyChecked()}
                                                        onCheckedChange={() => applyFilter(filter.type, value)}
                                                    />
                                                </div>
                                            )

                                        })}
                                    </AccordionContent>
                                </AccordionItem>
                            </motion.div>
                        )
                    })
                ))}
            </Accordion>
        </>
    )

    useMotionValueEvent(scrollY, "change", (latest) => {
        if (filterContainerRef?.current) {
            const { top } = filterContainerRef.current.getBoundingClientRect();
            const previous = scrollY.getPrevious();


            if (latest > previous || top > 80) {
                activatedPadding.value = false;
                return;
            }

            activatedPadding.value = true;
        }
    });

    useWindowSize((windowWidth) => {
        let isScreenMobile = windowWidth < 1280;

        if (!isScreenMobile) {
            isMobile.value = false;
            return;
        }
        isMobile.value = true;
    });

    useEffect(() => {
        const controller = new AbortController();
        const timeout = 10 * 1000;

        const timeoutId = setTimeout(() => {
            controller.abort();
        }, timeout);

        (async () => {
            try {
                const response = await fetchRetry(`${apiURL}/filters`, { signal: controller.signal });
                clearTimeout(timeoutId);

                if (response.ok) {
                    const data = [...filters.peek(), ...(await response.json()).filters];
                    setAccordionValues(data);
                    filters.value = data;
                    //console.log(data)
                    return;
                }

                console.error(`Errore ${response.status}: ${response.statusText}`);
            } catch (error) {
                console.error(error);
            }
        })()
        return () => {
            //console.log("Abort");
            controller.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (appliedFilters) {
            //console.log(appliedFilters);
            updateSearchParams()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliedFilters])

    useEffect(() => {
        const retrievedAppliedFilters = {}
        for (const [key, value] of searchParams.entries()) {
            if (key !== "category")
                retrievedAppliedFilters[key] = value.split('|');
        }
        setAppliedFilters(retrievedAppliedFilters);
    }, [searchParams])

    return (
        <div ref={filterContainerRef} className={`w-full h-fit transition-all duration-500 ease ${activatedPadding.value && "pt-[3.5rem] md:pt-[7rem] xl:pt-[8rem]"}`} >
            {isMobile.value ? (
                <Drawer direction="right">
                    <DrawerTrigger className='flex gap-1 bg-white text-[#635B55] shadow-md p-2 px-4 rounded-full transition-all my-2 hover:scale-110'>
                        <Filter className='scale-[0.8]' />
                        <span>Filtra</span>
                    </DrawerTrigger>
                    <DrawerContent className="bg-[#fcf8f3] flex flex-col justify-between rounded-t-[10px] h-full p-2 w-[85%] mt-24 rounded-none fixed bottom-0 left-[15%]">
                        <DrawerHeader className="p-0 pt-5 pb-1">
                            <DrawerTitle className="w-full flex justify-center">Filtro</DrawerTitle>
                            <Separator className="my-2" />
                        </DrawerHeader>
                        <div className='overflow-y-scroll w-full overflow-x-hidden'>
                            {filters.value?.length > 0 && (
                                displayFilters()
                            )}
                        </div>
                        <DrawerFooter className="w-full flex flex-row justify-end">
                            <div className='grid grid-cols-2 max-w-[15rem] gap-3'>
                                <Button variant="outline" className="bg-white" onClick={() => setAppliedFilters({})}>Cancella</Button>
                                <DrawerClose asChild>
                                    <Button className="w-full">Fatto</Button>
                                </DrawerClose>
                            </div>
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            ) : (
                filters.value?.length > 0 && (
                    displayFilters()
                )
            )}
        </div>
    )
}

export default Filters;