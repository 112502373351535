import { forwardRef } from 'react';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Button } from "@components/ui/button"



const MobileSearchButton = forwardRef(({ ...props }, ref) => {
    return (
        <div className="w-fit xl:hidden flex justify-end items-center">
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1.2 } }}
                whileTap={{ scale: 0.8 }}>
                <Button {...props} className="bg-white hover:bg-white rounded-full w-10 aspect-[1/1] shadow-md border-0 text-[#635B55]" ref={ref}>
                    <FontAwesomeIcon icon={faSearch} />
                </Button>
            </motion.div>
        </div>
    )
});

export default MobileSearchButton;