import { useRef } from "react";
import AutoScroll from 'embla-carousel-auto-scroll';

import {
    Carousel,
    CarouselContent,
    CarouselItem,
} from "@components/ui/carousel";



const AutoScrollImages = ({ direction, speed, images }) => {
    const plugin = useRef(
        AutoScroll({ playOnInit: true, speed: speed ? speed : 4, startDelay: 0, direction: direction ? direction : "backward" })
    )

    if (!images) return (<></>)

    return (
        <Carousel
            plugins={[plugin.current]}
            opts={{
                align: direction === "backward" ? "end" : "start",
                loop: true,
            }}
            className="w-full"
        >
            <CarouselContent className="">
                {images.map(({ src }, index) => (
                    <CarouselItem key={index} className="basis-1/8 pl-0">
                        <div className="p-1">
                            <img
                                fetchpriority="high"
                                src={src ? src : ""}
                                className={`rounded-sm h-[5rem] md:h-[24rem] bg-zinc-400 mr-2 ${!src ? "w-[5rem] md:w-[24rem]" : ""}`}
                                alt={index + 1}
                            />
                        </div>
                    </CarouselItem>
                ))}
            </CarouselContent>
        </Carousel>
    )
}

export default AutoScrollImages;