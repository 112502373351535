import { useNavigate } from 'react-router-dom'
import { motion } from "framer-motion";
import { Button } from '@components/ui/button';

const squareAnimation = {
    hidden: {
        translateX: '100%',
        opacity: 0
    },
    visible: {
        translateX: 0,
        opacity: 1,
        transition: { type: 'spring', duration: 1.2 }
    }
}

const PresentationSquare = () => {
    const navigate = useNavigate();

    return (
        <div className="top-0 left-0 bottom-0 w-full flex items-center justify-center lg:justify-end md:px-[6rem] px-[4rem]">
            <motion.div
                variants={squareAnimation}
                className={`
                    relative flex flex-col justify-center items-center sm:gap-[4rem] gap-10 md:w-full
                    w-fit max-w-[40rem] md:px-20 px-10 md:py-[3.5rem] py-5 bg-white/60
                    aspect-[4/3] sm:rounded-sm rounded-lg shadow-lg backdrop-blur-md
                `}
            >
                <h1 className="w-full font-semibold xl:text-6xl lg:text-5xl md:text-4xl sm:text-3xl text-2xl leading-[1.1em] text-[#3A3A3A]">
                    Scopri la nostra collezione
                </h1>
                <h3 className="font-normal md:text-xl text-sm md:leading-[1.1em] leading-4  text-[#898989]">
                    Scopri presso Nicol Casa il nostro assortimento per trovare gli elementi essenziali e gli accessori perfetti per ogni ambiente della tua casa.
                </h3>
                <Button
                    onClick={() => navigate({ pathname: '/catalogo' })}
                    className={`
                        md:py-[2rem] py-5 md:px-0 px-10 md:rounded-sm rounded-full shadow-md transition-all font-medium bg-[#E89F71] md:w-full text-white
                        hover:bg-white hover:border-black md:text-lg hover:text-black border-2
                        border-transparent z-30`
                    }
                >
                    <span className="capitalize">visualizza catalogo</span>
                </Button>

            </motion.div>
        </div>
    )
}

export default PresentationSquare;