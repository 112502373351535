import { useNavigate, createSearchParams } from 'react-router-dom';

import CategoryButton from '@components/categories/CategoryButton';

import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@components/ui/carousel";

const RelatedCategories = ({ images, selectedCategory }) => {
    const navigate = useNavigate();

    const navigateTo = (path) => {
        navigate({
            pathname: '/' + path.pathname.join('/'),
            search: createSearchParams(path.search).toString()
        })
    }

    const showCategories = () => {
        const selected_category = selectedCategory;
        const carouselCategories = selected_category ? images.filter((img) => {
            const category = img.name.toLowerCase();
            return selected_category !== category;
        }) : images;

        return carouselCategories.map((img, index) => {
            const category = img.name.toLowerCase();

            return (
                <CarouselItem key={index} className="basis-1/3 md:basis-1/4 px-2">
                    <div className='flex justify-center items-center ml-1'>
                        <CategoryButton
                            onClick={() => navigateTo({
                                pathname: ['prodotti'],
                                search: {
                                    category: category
                                }
                            })}
                            name={category}
                            src={img.src}
                        />

                    </div>
                </CarouselItem>
            )
        })
    }

    return (
        <section className='w-full flex justify-center items-center mb-20 md:mb-40 mt-5 md:mt-10 px-4 md:px-12 lg:px-20 2xl:px-52 overflow-hidden'>
            <Carousel
                opts={{
                    align: "start",
                }}
                className="w-full"
            >
                <CarouselContent className="-ml-1 pt-2 pb-6 my-20 px-2">
                    {showCategories()}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
            </Carousel>
        </section>
    )
}

export default RelatedCategories;