import { useNavigate } from 'react-router-dom';

import { Button } from '@components/ui/button';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const ProductPath = ({ product }) => {
    const navigate = useNavigate();

    const navigateTo = (url) => {
        setTimeout(() => {
            navigate(url)
        }, 200);
    }

    const getPath = (product) => {
        const path = [
            { id: "", name: 'home', url: "/" },
            ...product.categories.map((category) => ({ id: category.id, name: category.name, url: `/prodotti?category=${category.name}` })),
            { id: "", name: product.name, url: "#" },
        ];
        const lastIndex = path.length - 1;

        return path.map((item, index) => (
            <div className="flex justify-center items-center w-fit cursor-pointer" key={index}>
                <div
                    onClick={() => navigateTo(item.url)}
                    className={`
                        capitalize transition-all p-0 border-b-2 rounded-none border-b-transparent
                        bg-transparent hover:bg-transparent h-full md:text-lg sm:text-sm text-[13px]
                        ${lastIndex === index ?
                            ('h-full text-black font-semibold hover:border-b-black ')
                            : ('hover:border-b-zinc-600 text-zinc-600')
                        }
                    `}>
                    <h1>{item.name}</h1>
                </div>
                {
                    lastIndex !== index && (
                        <>
                            <FontAwesomeIcon icon={faChevronRight} className='select-none mx-1 p-0 lg:text-sm md:text-[11px] text-[8px] font-bold' />
                        </>

                    )
                }
            </div>
        ))
    }

    if (!product.value) {
        return (<></>);
    }

    return (
        <div className="flex flex-wrap h-full items-center text-zinc-700 py-2">
            {getPath(product.value)}
        </div>
    );
}

export default ProductPath;