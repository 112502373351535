import { useEffect, useRef, forwardRef } from "react"
import { useNavigate, /* useParams, */ createSearchParams } from 'react-router-dom';
import { useSignal } from '@preact/signals-react';

import BackgroundImage from '@components/ui/BackgroundImage';
import CategoryButton from '@components/categories/CategoryButton';

import { useContext } from "react";
import { LoadingPage } from "@app";

const image = {
    main: {
        src: "https://images.unsplash.com/photo-1565031491910-e57fac031c41?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    }
}

const fetchCategories = async () => {
    try {
        const apiURL = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiURL}/categories`, {
            method: 'GET',
            mode: 'cors',
        });

        if (response.ok) {
            const json = await response.json();
            return [...json.categories]
        }

        const errorResponse = await response.json()
        throw new Error(`${errorResponse.message}`);
    } catch (error) {
        console.error(error.message);
        return false;
    }
}


const Catalog = forwardRef(() => {
    const { setIsLoading } = useContext(LoadingPage)


    const navigate = useNavigate();
    const categories = useSignal(null);
    const categoriesRef = useRef(null);


    const navigateTo = (path) => {
        navigate({
            pathname: '/' + path.pathname.join('/'),
            search: createSearchParams(path.search).toString()
        })
    }
    const getCategories = async () => {
        categories.value = await fetchCategories();
        console.log(categories.peek())
        setIsLoading(false);
    }

    useEffect(() => {
        setIsLoading(true);
        window.scrollTo(0, 0);
        getCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <BackgroundImage src={image.main.src} onClick={() => { categoriesRef.current.scrollIntoView({ behavior: 'smooth' }) }} />
            <section className="md:min-h-screen h-fit">
                <div ref={categoriesRef} className="w-full h-fit px-6 md:px-20 flex flex-col justify-center items-center gap-16 mb-40 mt-20">
                    <h4 className="text-black font-bold text-6xl md:text-7xl lg:text-8xl py-10 hidden md:flex">Categorie</h4>
                    <div className="flex flex-wrap justify-center items-center gap-5 md:gap-10">
                        {categories.value && Array.isArray(categories.value) && categories.value.find(({ name }) => name === "categoria").childs.map((category, index) => {
                            return (
                                <CategoryButton
                                    onClick={() => navigateTo({
                                        pathname: ['prodotti'],
                                        search: {
                                            category: category.name
                                        }
                                    })}
                                    key={index}
                                    name={category.name}
                                    src={category.image}
                                />
                            )
                        })}
                    </div>
                </div>
            </section>
        </>
    )
});


export default Catalog;



/* const ImageNav = ({ name, src, onClickButton }) => {
    return (
        <div className="flex flex-col justify-center items-center gap-4 text-center relative bg-transparent overflow-hidden rounded-sm shadow-md">
            <img src={src} className="w-full aspect-[1/1] object-cover" />
            <div className="absolute w-full h-full flex flex-col justify-end pb-[3.5rem] text-black items-center">
                <Button
                    onClick={onClickButton}
                    className={`top-0 left-0 py-2 px-10 uppercase text-md font-medium hover:bg-black bg-[rgb(255,255,255,0.9)] border-black hover:text-white text-black border-2`}
                >
                    <span>{name}</span>
                </Button>
            </div>
        </div>
    )
}

const SelectableOptions = ({ onClickMainButon, onClickFirstOpt, onClickSecondOpt }) => {
    return (
        <div className='w-full flex flex-col gap-20'>
            <div className='flex md:flex-nowrap flex-wrap justify-center xl:gap-48 lg:gap-32 md:gap-20  gap-10 items-center w-full'>
                <ImageNav name="stile" src="/img/stile.png" onClickButton={onClickFirstOpt} />
                <ImageNav name="categoria" src="/img/categoria.png" onClickButton={onClickSecondOpt} />
            </div>
            <div className='w-full flex justify-center items-center'>
                <Button onClick={onClickMainButon} className="hover:bg-white bg-black hover:opacity-100 border-2 border-black hover:text-black text-white text-xl uppercase px-[4rem] py-7">Visualizza tutto</Button>
            </div>
        </div>
    )
}

const fetchCategories = async () => {
    try {
        const apiURL = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiURL}/categories`, {
            method: 'GET',
            mode: 'cors',
        });

        if (response.ok) {
            const json = await response.json();
            return { stile: json.styles, categoria: json.categories }
        }

        const errorResponse = await response.json()
        throw new Error(`${errorResponse.message}`);
    } catch (error) {
        console.error(error.message);
        return false;
    }
}

const Catalog = () => {
    const { filter } = useParams();
    const navigate = useNavigate();
    const options = useSignal(null)

    const animation = useMemo(() => (
        {
            hidden: {
                opacity: 0,
                scale: 0
            },
            visible: {
                opacity: 1,
                scale: 1,
                transition: { type: 'spring', duration: .5, delay: .3 }
            }
        }
    ), []);

    const navigateTo = (path) => {
        navigate({
            pathname: '/' + path.pathname.join('/'),
            search: createSearchParams(path.search).toString()
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [filter])

    useEffect(() => {
        (async () => {
            options.value = await fetchCategories();
        })();
    }, [])

    return (
        <div className="w-full flex flex-col gap-20 justify-center items-center py-10 pb-80 2xl:px-[20%] lg:px-[10%] px-[6%]">
            <div className='w-full flex flex-col gap-6 justify-center items-center'>
                <h1 className='w-full font-semibold text-3xl uppercase'>Catalogo</h1>
                <h3 className='text-xl'>Qui puoi scoprire la nostra vasta gamma di arredamenti di qualità e design. Puoi cercare per <span className='font-extrabold text-orange-600 cursor-pointer'>stile</span> e per <span className='font-extrabold text-sky-950 cursor-pointer'>categoria</span> in base alle tue preferenze e alle tue esigenze. Abbiamo prodotti per tutti gli stili, dal classico al moderno e per tutte le categorie come divani, sedie, materassi, ecc...</h3>
            </div>
            {options.value && (filter === "stile" || filter === "categoria") ? (
                <motion.div variants={animation} initial="hidden" animate="visible" className='flex flex-col gap-5 justify-center items-center w-full'>
                    <div className={`w-full grid md:grid-cols-2 justify-center items-center gap-4 pb-20`}>
                        {
                            options.value[filter].map((option, index) => {
                                return (
                                    <ImageNav
                                        key={`${option.id}_${index}`}
                                        name={option.name}
                                        src={option.image}
                                        onClickButton={() =>
                                            navigateTo({
                                                pathname: ['prodotti'],
                                                search: {
                                                    category: option.name
                                                }
                                            })
                                        } />
                                )
                            })
                        }
                    </div>
                </motion.div>
            ) : (
                <motion.div variants={animation} initial="hidden" animate="visible">
                    <SelectableOptions
                        onClickMainButon={() =>
                            navigateTo({
                                pathname: ['prodotti'],
                                search: {}
                            })
                        }
                        onClickFirstOpt={() =>
                            navigateTo({
                                pathname: ['catalogo', 'stile'],
                                search: {}
                            })
                        }
                        onClickSecondOpt={() =>
                            navigateTo({
                                pathname: ['catalogo', 'categoria'],
                                search: {}
                            })
                        } />
                </motion.div>
            )}
        </div>
    )
} */
