import { useEffect, useRef, useState } from "react";
import { useSignal } from '@preact/signals-react';
import { motion, useAnimate } from 'framer-motion'
import { useSpring, animated } from '@react-spring/web'
import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react'

import {
    Dialog,
    DialogContent,
    /*     DialogHeader,
        DialogTitle, */
    DialogTrigger,
} from "@components/ui/dialog"

import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@components/ui/carousel"

const useGesture = createUseGesture([dragAction, pinchAction])

const CarouselImage = ({ src, onZoomStart, onZoomEnd }) => {
    const crop = useSignal({ x: 0, y: 0, scale: 1 });
    const imgRef = useRef()

    const setCrop = (value) => {
        crop.value = value;
    }


    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: 1,
    }))

    useGesture(
        {
            onDrag: ({ pinching, cancel, offset: [x, y] }) => {
                if (pinching) return cancel()
                if (crop.peek().scale > 1) {
                    const limit = {
                        right: -((imgRef.current.scrollWidth * crop.value.scale) / 2) + window.innerWidth / 2,
                        left: ((imgRef.current.scrollWidth * crop.value.scale) / 2) - window.innerWidth / 2,
                        top: ((imgRef.current.scrollHeight * crop.value.scale) / 2) - imgRef.current.scrollHeight * 2,
                        bottom: -((imgRef.current.scrollHeight * crop.value.scale) / 2) + imgRef.current.scrollHeight * 2,
                    }

                    console.log(limit.top)
                    console.log(limit.bottom)
                    api.start({
                        x: x > limit.left || x < limit.right ? crop.peek().x : x,
                        y: y < limit.top || y > limit.bottom ? crop.peek().y : y,
                    })

                    setCrop({
                        ...crop.peek(),
                        x: x > limit.left || x < limit.right ? crop.peek().x : x,
                        y: y < limit.top || y > limit.bottom ? crop.peek().y : y,
                    });
                }
            },
            onPinch: ({ origin: [ox, oy], first, movement: [ms], offset: [s], memo }) => {
                if (s > 1) {
                    onZoomStart();
                } else {
                    api.start({ x: 0, y: 0 })
                    onZoomEnd();
                }

                if (first) {
                    const { width, height, x, y } = imgRef.current.getBoundingClientRect();
                    const tx = ox - (x + width / 2);
                    const ty = oy - (y + height / 2);
                    memo = [style.x.get(), style.y.get(), tx, ty];
                }

                const x = memo[0] - (ms - 1) * memo[2];
                const y = memo[1] - (ms - 1) * memo[3];
                api.start({ scale: s, x: s <= 1 ? 0 : x, y: s <= 1 ? 0 : y });
                setCrop({
                    ...crop.peek(),
                    scale: s,
                });
                return memo;
            },
        },
        {
            target: imgRef,
            drag: { from: () => [style.x.get(), style.y.get()] },
            pinch: { scaleBounds: { min: 1, max: 3 }, rubberband: true },
        }
    )

    useEffect(() => {
        const handler = (e) => e.preventDefault()
        document.addEventListener('gesturestart', handler)
        document.addEventListener('gesturechange', handler)
        document.addEventListener('gestureend', handler)
        return () => {
            document.removeEventListener('gesturestart', handler)
            document.removeEventListener('gesturechange', handler)
            document.removeEventListener('gestureend', handler)
        }
    }, []);

    return (
        <CarouselItem className="flex justify-center items-center md:max-h-[90vh] h-screen overflow-hidden" >
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: .2, duration: .3 } }}
                className="w-[80%] aspect-[1/1] flex justify-center items-center p-5 relative">
                <animated.img
                    draggable="false"
                    ref={imgRef}
                    variant="outline"
                    className="cursor-pointer select-none w-full md:rounded-sm object-contain aspect-[1/1] md:static absolute"
                    style={{
                        touchAction: 'none',
                        ...style
                    }}
                    src={src} />
            </motion.div>
        </CarouselItem>
    )
}

const ProductImages = ({ product }) => {
    const gridCurrent = useSignal(0);
    const openedCurrent = useSignal(0);
    const watchDrag = useSignal(false);

    const [carouselApi, setCarouselApi] = useState();
    const [imageTriggerRef, animateImageTriggerRef] = useAnimate();

    const setWatchDrag = (value) => {
        watchDrag.value = value;
    }

    const getImagesGrid = () => {
        return product.value.images.map((img, index) => (
            <div key={index} className="aspect-1 overflow-hidden rounded-sm shadow-md">
                <motion.img
                    whileTap={{ scale: 0.9, transition: { duration: .01 } }}
                    onClick={() => {
                        changeImageIndex(index)
                    }}
                    className="cursor-pointer w-full h-full object-cover" src={img.src} />
            </div>
        ));
    }

    const changeImageIndex = async (index) => {
        if (index !== gridCurrent.peek()) {
            await animateImageTriggerRef(imageTriggerRef.current, { opacity: 0 }, { duration: 0.1 })
            gridCurrent.value = index;
            await animateImageTriggerRef(imageTriggerRef.current, { opacity: 1 }, { duration: 0.1 })
        }
    }

    useEffect(() => {
        if (!carouselApi) {
            return
        }

        carouselApi.on('init', () => {
            carouselApi.scrollTo(gridCurrent.peek(), true);
        })

        carouselApi.on('settle', () => {
            openedCurrent.value = carouselApi.selectedScrollSnap();
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carouselApi])

    if (!product.value) {
        return (<></>);
    }


    return (
        <>
            <Dialog onOpenChange={(value) => setWatchDrag(value)}>
                <DialogTrigger asChild>
                    <div className="w-full rounded-md shadow-md overflow-hidden">
                        <img ref={imageTriggerRef} variant="outline" alt="" className="cursor-pointer w-full aspect-[1/1] object-cover" src={product.value.images[gridCurrent.value].src} />
                    </div>
                </DialogTrigger>
                <DialogContent className="md:w-[85%] md:h-fit md:max-w-screen-xl h-full w-full md:px-6 px-0 bg-white">
                    <Carousel
                        className="w-full h-full"
                        setApi={setCarouselApi}
                        opts={{
                            loop: true,
                            watchDrag: watchDrag.value,
                        }}>
                        <CarouselContent>
                            {Array.from({ length: product.value.images.length }).map((_, index) => (
                                <CarouselImage
                                    key={index}
                                    src={product.value.images[index].src}
                                    onZoomStart={() => setWatchDrag(false)}
                                    onZoomEnd={() => setWatchDrag(true)} />
                            ))}
                        </CarouselContent>
                        <CarouselPrevious className="left-1 top-[98%]" />
                        <CarouselNext className="right-1 top-[98%]" />
                    </Carousel>
                </DialogContent>
            </Dialog>

            <div className="grid grid-cols-4 gap-4">
                {getImagesGrid(carouselApi)}
            </div>
        </>
    )
}

export default ProductImages;