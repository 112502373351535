import { forwardRef, useEffect } from "react";
import { motion, useAnimate } from "framer-motion";
import { Sparkle } from "lucide-react"
import { computed, useSignal } from '@preact/signals-react';
import { useWindowSize } from '@lib/hooks/useWindowSize.js'

const textReviews = [
    {
        src: "/img/reviews/luca_f.jpg",
        text: "Ho apprezzato molto la mia visita a questo negozio di arredamento. L'atmosfera era accogliente, il personale cortese e competente, e ho trovato dei pezzi davvero unici per la mia casa. Sicuramente tornerò per i miei prossimi acquisti!",
        username: "Luca F.",
    },
    {
        src: "/img/reviews/francesco_c.jpg",
        text: "Sono rimasto soddisfatto della mia esperienza di shopping in questo negozio. Hanno una buona selezione di mobili di qualità e il personale è stato disponibile senza essere invadente. Ho trovato ciò di cui avevo bisogno a un prezzo ragionevole.",
        username: "Francesco C.",
    },
    {
        src: "/img/reviews/nicola_p.jpg",
        text: "Ho trovato questo negozio di arredamento per caso e sono rimasto piacevolmente sorpreso dalla varietà e dalla qualità dei loro mobili. Il personale è stato gentile e disponibile, e ho trovato esattamente quello che cercavo a un prezzo conveniente. Consigliatissimo!",
        username: "Nicola P.",
    },
]

const animation = {
    toRight: {
        hidden: {
            translateX: '-200%',
            opacity: 0,
        },
        visible: {
            translateX: 0,
            opacity: 1,
            transition: { type: 'spring', duration: 0.3, delay: 0.5 }
        }
    },
    toLeft: {
        hidden: {
            translateX: '200%',
            opacity: 0,
        },
        visible: {
            translateX: 0,
            opacity: 1,
            transition: { type: 'spring', duration: 0.3, delay: 0.5 }
        }
    }
}

const Card = forwardRef(({ id, text, username, src, x = 3, y = 1, zIndex = 50 }, ref) => {
    return (
        <motion.div
            id={id}
            style={{ x: `${x}rem`, y: `${y}rem`, zIndex }}
            className={`absolute top-0 left-0 bg-white sm:w-[80%] w-[85%] rounded-xl aspect-[16/9] shadow-xl`}>
            <div className={`
                relative w-full aspect-[16/9] flex flex-col justify-between
                items-center gap-3 p-5 md:p-10 md:pr-20
            `}>
                <img alt="" src={src} className="sm:w-14 w-10 aspect-1 absolute -top-5 -left-5 rounded-full bg-zinc-400" />
                <p className="text-[#5E6282] xl:text-base sm:text-sm text-[11px]">{text}</p>
                <h6 className="w-full xl:text-base sm:text-sm text-[11px] text-[#5E6282] font-semibold">{username}</h6>
            </div>
        </motion.div>
    )
})

const Reviews = forwardRef(() => {
    const [cardRef, animateCard] = useAnimate();
    const isScreenMobile = useSignal(false)
    const cardDefaultPosition = computed(() => !isScreenMobile.value ? ({ x: 3, y: 1, next: 1.3 }) : ({ x: 1.4, y: 1, next: 0.7 }))

    useWindowSize((windowWidth) => {
        isScreenMobile.value = windowWidth < 640;
    });

    useEffect(() => {
        let cardIndex = 0;
        const cardsAnimation = async (cardIndex, initial, animate) => {
            await animateCard(cardRef.current.querySelector(`#card${cardIndex}`), initial, {
                duration: 0.4,
            });

            await animateCard(cardRef.current.querySelector(`#card${cardIndex}`), animate, {
                duration: 0.25,
            });
        }

        const cardInterval = setInterval(async () => {
            const x = cardDefaultPosition.peek().x + (cardDefaultPosition.peek().next * (textReviews.length - 1));
            const y = cardDefaultPosition.peek().y + (cardDefaultPosition.peek().next * (textReviews.length - 1));

            cardsAnimation(cardIndex, {
                x: "20%",
                y: "-50%",
                userSelect: 'none',
                opacity: 0.5,
                zIndex: 60,
            }, {
                x: x + 'rem',
                y: y + 'rem',
                userSelect: 'auto',
                opacity: 1,
                zIndex: 0
            });

            let currentIndex = cardIndex + 1
            let index = 0;

            while (index < textReviews.length - 1) {
                if (currentIndex > textReviews.length - 1) currentIndex = 0;
                if (currentIndex !== cardIndex) {
                    const x = cardDefaultPosition.peek().x + (cardDefaultPosition.peek().next * index);
                    const y = cardDefaultPosition.peek().y + (cardDefaultPosition.peek().next * index);
                    const zIndex = (textReviews.length * 10) - (index + 1 * 10);
                    //console.log(`${currentIndex}: X: ${x}rem | Y: ${y}rem`)
                    animateCard(cardRef.current.querySelector(`#card${currentIndex}`), {
                        x: x + 'rem',
                        y: y + 'rem',
                        zIndex
                    }, {
                        duration: 0.5,
                    });
                    index++;
                }
                currentIndex++;
            }

            cardIndex += 1;
            if (cardIndex === textReviews.length) {
                cardIndex = 0;
            }
        }, 6 * 1000);

        return () => {
            clearInterval(cardInterval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <motion.section initial="hidden" whileInView="visible" viewport={{ once: true }} className="min-h-80 w-full 2xl:px-40 sm:px-10 my-40 overflow-x-clip pb-10">
            <div className="grid lg:grid-cols-3 grid-cols-2 w-full gap-10">
                <motion.div variants={animation.toRight} className="sm:col-span-1 col-span-2 flex flex-col justify-start items-start sm:w-[16rem] w-full scale-125 origin-left gap-4 sm:px-0 px-5">
                    <h5 className="font-medium uppercase text-md text-[#5E6282]">Testimonianze</h5>
                    <h2 className="font-bold capitalize text-[#14183E] text-4xl leading-snug">Cosa pensano i clienti...</h2>
                </motion.div>
                <motion.div variants={animation.toLeft} className="col-span-2 flex justify-center items-center ms:px-0 px-5">
                    <div ref={cardRef} className="bg-[#EEE5DE] 2xl:w-[70%] xl:w-[80%] lg:w-[95%] md:w-[80%] w-full aspect-[4/3] rounded-[59%_41%_47%_53%_/_45%_58%_42%_55%] shadow-xl relative">
                        {textReviews.map(({ text, username, src }, index) => {
                            const x = cardDefaultPosition.value.x + (cardDefaultPosition.value.next * index);
                            const y = cardDefaultPosition.value.y + (cardDefaultPosition.value.next * index);
                            const zIndex = (textReviews.length * 10) - (index + 1 * 10);

                            return (
                                <Card
                                    key={`card${index}`}
                                    id={`card${index}`}
                                    text={text}
                                    username={username}
                                    src={src}
                                    x={x}
                                    y={y}
                                    zIndex={zIndex}
                                />
                            )
                        })}
                        <div className="absolute bottom-[25%] sm:translate-y-0 translate-y-8 sm:bottom-[35%] left-[2%] scale-110 sm:scale-150">
                            <Sparkle className="sm:-translate-x-[2rem] -translate-x-[1rem] sm:my-3 my-1" />
                            <Sparkle />
                        </div>

                        <div className="absolute top-0 right-[2%] sm:rotate-0 -rotate-12 sm:translate-x-[1rem] -translate-x-[1rem] sm:translate-y-0 -translate-y-[2.5rem] flex flex-col gap-5 scale-[0.6] sm:scale-100">
                            <div className="sm:h-[4px] h-[5px] bg-black w-[4rem] translate-y-[0.2rem] -translate-x-[0.5rem] -rotate-[65deg] rounded-full" />
                            <div className="sm:h-[4px] h-[5px] bg-black w-[3rem] translate-y-[0.2rem] translate-x-[1.1rem] -rotate-45 rounded-full" />
                            <div className="sm:h-[4px] h-[5px] bg-black w-[4rem] translate-x-[2rem] -rotate-[20deg] rounded-full" />
                        </div>
                    </div>
                </motion.div>
            </div>
        </motion.section>
    )
});

export default Reviews;