
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import {
    faFacebook,
    faInstagram
} from "@fortawesome/free-brands-svg-icons";

import { motion } from 'framer-motion'
import { useContext, useEffect } from "react";
import { LoadingPage } from "@app";

const animation = {
    title: {
        hidden: {
            translateX: "20%", opacity: 0
        },
        visible: {
            translateX: 0, opacity: 1, transition: { delay: .5, duration: .5, type: "spring" }
        }
    },
    paragraph: {
        hidden: {
            translateY: "-20%", opacity: 0
        },
        visible: {
            translateY: 0, opacity: 1,
            transition: { delay: .8, duration: .5, type: "spring" }
        }
    },
    img: {
        hidden: {
            translateY: "20%", opacity: 0
        },
        visible: {
            translateY: 0, opacity: 1, transition: { delay: 1 }
        }
    },
    contacts: [
        {
            hidden: {
                translateX: "50%", opacity: 0
            },
            visible: {
                translateX: 0, opacity: 1, transition: { delay: .5, duration: .5, type: "spring" }
            }
        },
        {
            hidden: {
                translateX: "-50%", opacity: 0
            },
            visible: {
                translateX: 0, opacity: 1, transition: { delay: .8, duration: .5, type: "spring" }
            }
        }
    ]
}

const Contacts = () => {
    const { setIsLoading } = useContext(LoadingPage);

    useEffect(() => {
        window.scroll(0, 0);
        setTimeout(() => {
            setIsLoading(false);

        }, 500)
    })

    return (
        <motion.div
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true }}
            className='flex flex-col h-fit md:gap-32 gap-10 justify-evenly min-h-screen font-satoshi p-[6%] text-black'
        >
            <div className="flex flex-col justify-center gap-8 lg:w-[85%] w-full">
                <motion.h1 variants={animation.title} className='font-semibold xl:text-6xl lg:text-5xl md:text-4xl text-3xl leading-[1.1em]'>
                    Contattaci per ricevere informazioni sui prodotti o per assistenza
                </motion.h1>
                <motion.p variants={animation.paragraph} className='flex flex-col font-normal text-sm lg:text-lg xl:text-xl'>
                    <span>Vuoi esplorare le nostre offerte?</span>
                    <span>Scrivici per ricevere informazioni complete sui nostri servizi e prodotti.</span>
                    <span className='mt-8'>Rivoluziona il tuo spazio vitale con un tocco di stile unico! Siamo qui per te, pronti a offrirti consulenze personalizzate. Che tu preferisca una chiamata amichevole o un'email dettagliata, siamo pronti a ascoltarti. Chiamaci per un contatto immediato o inviaci una e-mail con le tue idee e domande. La tua visione per un ambiente perfetto diventa realtà con il nostro supporto dedicato. Siamo ansiosi di sentirti e rendere il tuo arredamento un'esperienza indimenticabile!</span>
                </motion.p>
            </div>
            <motion.div variants={animation.img}>
                <img
                    src="/img/backgrounds/uomo-in-chiamata.png"
                    alt=""
                    className='lg:aspect-[21/9] md:aspect-[16/9] aspect-[1/1] w-full object-cover rounded-sm shadow-md shadow-gray-400'
                />
            </motion.div>
            <div className='w-full min-h-0'>
                <div className='w-full grid grid-cols-2 gap-5'>
                    <motion.div
                        variants={animation.contacts[0]}
                        viewport={{ once: true }}
                        className={`
                            xl:col-span-1 col-span-2 w-full min-h-64 h-64 border-[1px] border-zinc-200
                            bg-white shadow-md rounded-md flex flex-col py-8 px-5 justify-between text-3xl
                        `}>
                        <div>
                            <h2 className="w-full font-bold md:font-semibold text-2xl md:text-3xl leading-[1.1em] pb-2">Email:</h2>
                            <div className='flex justify-start items-center gap-5'>
                                <h3 className='font-normal text-sm md:text-xl leading-[1.1em]'>Invia un email a:
                                    <a className="font-semibold transition-colors hover:text-blue-400 text-blue-700" href="mailto:info@nicolcasa.it"> info@nicolcasa.it</a>
                                </h3>
                            </div>
                        </div>
                        <div>
                            <h2 className="w-full font-bold md:font-semibold text-2xl md:text-3xl leading-[1.1em] pb-2">Social:</h2>
                            <div className='flex justify-start items-center gap-5'>
                                <a href="https://www.facebook.com/messages/t/113803138990568" target="_blank" rel="noreferrer" title="Facebook Messenger">
                                    <FontAwesomeIcon icon={faFacebook} />
                                </a>
                                <a href="https://www.instagram.com/direct/t/17843162735271232" target="_blank" rel="noreferrer" title="Instagram DM">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div
                        variants={animation.contacts[1]}
                        viewport={{ once: true }}
                        className={`
                            xl:col-span-1 col-span-2 w-full min-h-64 h-[18rem] md:h-[21rem] border-[1px] text-3xl
                            border-zinc-200 bg-white shadow-md rounded-md flex flex-col py-8 px-5 justify-between
                        `}>
                        <div>
                            <h2 className="w-full font-bold md:font-semibold text-2xl md:text-3xl leading-[1.1em] pb-2">Telefono:</h2>
                            <div className='flex flex-col justify-between items-center gap-10'>
                                <h3 className='font-normal text-sm w-full md:text-xl leading-[1.1em]'>
                                    <a className="font-semibold transition-colors hover:text-blue-400 text-blue-700" href="tel:+393927533470">
                                        <FontAwesomeIcon icon={faPhone} /> +39 392 753 3470
                                    </a>
                                </h3>
                            </div>
                        </div>
                        <div className='flex flex-col text-sm w-full'>
                            <h2 className="w-full font-semibold text-2xl md:text-3xl leading-[1.1em] pb-2">Orari di apertura:</h2>
                            <div className='w-full text-md md:text-xl leading-[1.1em] flex flex-col gap-3'>
                                <div>
                                    <h3 className='font-semibold'>Lunedì, Mercoledì e Sabato:</h3>
                                    <p className='font-normal text-sm md:text-md'>dalle 09:00 alle 13:00; dalle 17:30 alle 21:00.</p>
                                </div>
                                <div>
                                    <h3 className='font-semibold'>Martedì, Giovedì, Venerdì:</h3>
                                    <p className='font-normal text-sm md:text-md'>dalle 09:00 alle 13:00; dalle 17:30 alle 20:30.</p>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </motion.div>
    )
}

export default Contacts;