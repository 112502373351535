import { createContext, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import LoadingCircle from '@components/ui/LoadingCircle';
import Home from '@pages/Home';
import Product from '@pages/Product'
import ProductList from '@pages/ProductList'
import Catalog from '@pages/Catalog'
import Contacts from '@pages/Contacts'
import NotFound from '@pages/NotFound';

import Header from "@components/header/Header"
import Footer from "@components/ui/Footer"


export const LoadingPage = createContext(null);

function App() {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <LoadingPage.Provider value={{ isLoading, setIsLoading }}>
      {/* <div className='absolute w-screen overflow-hidden top-0 left-0 blur-sm z-0 flex flex-col justify-center items-center'>
        <div className="w-[80rem] aspect-[1/1] bg-[#EEE5DE] translate-y-[-10rem] translate-x-[40%] lg:translate-x-[45%] xl:translate-x-[50%] 2xl:translate-x-[55%] rounded-full shadow-[0_0px_60px_-15px_rgba(0,0,0,0.3)] shadow-zinc-400" />
        <div className="w-[80rem] aspect-[1/1] bg-[#EEE5DE] rounded-full shadow-[0_0px_60px_-15px_rgba(0,0,0,0.3)] shadow-zinc-400" />
      </div> */}
      <Header />
      <LoadingCircle />
      <AnimatePresence>
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: .5, delay: .4 } }}
          exit={{ opacity: 0, transition: { duration: .5, delay: .4 } }}
          className={`relative ${isLoading ? "hidden" : ""}`}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/catalogo" element={<Catalog />} />
            <Route path="/catalogo/:filter" element={<Catalog />} />
            <Route path="/prodotti" element={<ProductList />} />
            <Route path="/prodotti/:pid" element={<Product />} />
            <Route path="/prodotti/:pid/:vid" element={<Product />} />
            <Route path="/contatti" element={<Contacts />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </motion.main>
      </AnimatePresence>
    </LoadingPage.Provider>
  );
}

export default App;
