import { useNavigate, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'

import { Button } from "@components/ui/button"

import {
    NavigationMenu,
    NavigationMenuItem,
    NavigationMenuList,
} from "@components/ui/navigation-menu"

const HorizontalPanelLinks = ({ links }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const navigateTo = (url) => {
        setTimeout(() => {
            navigate(url)
        }, 500)
    }

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 1.2 } }} className='w-fit xl:flex hidden justify-start items-center'>
            <NavigationMenu>
                <NavigationMenuList className="flex text-xl font-medium  text-[#FFF2D0] border-0 outline-0 gap-[2.5rem] 2xl:gap-[4rem]">
                    {
                        links.map((link, index) => {
                            return (
                                <NavigationMenuItem key={index}>
                                    <Button onClick={() => { navigateTo(link.href) }} className='relative animated-underline bg-transparent hover:bg-transparent h-fit p-0' key={index} index={index} href={link.href}>
                                        <span className={`
                                            transition-all text-xl text-[#635B55]
                                            ${location.pathname === link.href || (link.href === '/catalogo' && location.pathname.includes('/prodotti')) ?
                                                'font-bold' : 'font-normal'}
                                        `}>
                                            {link.title}
                                        </span>
                                        <div className='transition-all duration-200 absolute origin-top-center bottom-0 left-0 w-full scale-x-0 h-[2px] bg-[#635B55]' />
                                    </Button>
                                </NavigationMenuItem>
                            )
                        })
                    }
                </NavigationMenuList>
            </NavigationMenu>
        </motion.div>
    )
}

export default HorizontalPanelLinks;

/*
    {
        !Array.isArray(link.childs) ? (
            <Button onClick={() => { navigateTo(link.href) }} className='relative animated-underline bg-transparent hover:bg-transparent h-fit p-0' key={index} index={index} href={link.href}>
                <span className='text-xl font-semibold'>{link.title}</span>
                <div className='transition-all duration-200 absolute origin-top-center bottom-0 left-0 w-full scale-x-0 h-[2px] bg-[#FFF2D0]' />
            </Button>
        ) : (
            <>
                <NavigationMenuTrigger className="bg-transparent hover:bg-transparent hover:text-white text-white focus:bg-transparent animated-underline data-[state=open]:bg-transparent">
                    <div onClick={() => { navigateTo(link.href) }} className='relative  animated-underline bg-transparent hover:bg-transparent h-fit p-0' key={index} index={index} href={link.href}>
                        <span className='text-xl font-semibold'>{link.title}</span>
                        <div className='transition-all duration-200 absolute origin-top-center bottom-0 left-0 w-full scale-x-0 h-[2px] bg-[#FFF2D0]' />
                    </div>
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                    <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                        {link.childs.map((childLink) => (
                            <ListItem
                                key={childLink.title}
                                title={childLink.title}
                                href={'/prodotti' + childLink.href}
                            >
                                {childLink.description}
                            </ListItem>
                        ))}
                    </ul>
                </NavigationMenuContent>
            </>
        )
    }
*/