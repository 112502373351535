import { BadgeCheck, Trophy } from 'lucide-react';
import { motion } from 'framer-motion';

const PresentationBanners = () => {
    return (
        <div className="w-full h-[10rem] flex items-start justify-center lg:justify-end md:px-[6rem] sm:px-[4rem] px-[1rem]">
            <div className="w-[40rem] h-full grid grid-cols-2">
                <motion.div
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1, transition: { delay: .5 } }}
                    className="grid grid-cols-3">
                    <div className="flex justify-center items-center scale-150">
                        <Trophy />
                    </div>
                    <div className="col-span-2 flex flex-col justify-center items-start gap-2">
                        <h5 className="font-semibold capitalize xl:text-xl md:text-base">Alta qualità</h5>
                        <h6 className="first-letter:capitalize text-zinc-400 max-w-[15rem] md:text-base text-[11px]">Materiali top di gamma</h6>
                    </div>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1, transition: { delay: .8 } }}
                    className="grid grid-cols-3">
                    <div className="flex justify-center items-center scale-150">
                        <BadgeCheck />
                    </div>
                    <div className="col-span-2 flex flex-col justify-center items-start gap-2">
                        <h5 className="font-semibold capitalize xl:text-xl md:text-base">Garanzia assicurata</h5>
                        <h6 className="first-letter:capitalize text-zinc-400 max-w-[15rem] md:text-base text-[11px]">Centro di assistenza autorizzato in sede</h6>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default PresentationBanners;