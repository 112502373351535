import { Fragment, useEffect } from 'react';
import { useSignal } from '@preact/signals-react';
import { useNavigate, useLocation } from 'react-router-dom'
import { Button } from '@components/ui/button'
import { X } from 'lucide-react';

const ProductAttributes = ({ product }) => {
    const selectedAttributes = useSignal([]);
    const location = useLocation();
    const navigate = useNavigate();

    const setSelectedAttributes = (name, option) => {
        if (product.peek().type !== "variable") return;
        const attributes = [...selectedAttributes.peek()];
        for (const index in attributes) {
            const attr = attributes[index];

            if (attr.name !== name) continue;
            if (attr.name === name && attr.option === option) break;

            attributes.splice(index, 1, { ...attr, option });
            break;
        }
        selectedAttributes.value = attributes;

        let matchedVariantID = null;
        for (const variant of product.peek().variants) {
            const isMatch = selectedAttributes.peek().every(({ option }, attrIndex) => {
                const variantAttribute = variant.attributes[attrIndex];
                return option === variantAttribute.option;
            });

            if (isMatch) { matchedVariantID = variant.id; break; }
        }
        const path = "/" + location.pathname.slice(1).split("/").slice(0, 2).join("/");

        if (matchedVariantID && typeof matchedVariantID === "number") {
            navigate(`${path}/${matchedVariantID}`);
        }
    }

    const isSelectable = (name, option) => {
        const attributeCombination = [...selectedAttributes.peek()];
        for (const index in attributeCombination) {
            const attr = attributeCombination[index];

            if (attr.name !== name) continue;
            if (attr.name === name && attr.option === option) break;

            attributeCombination.splice(index, 1, { ...attr, option });
            break;
        }

        let isMatch = false;
        for (const variant of product.peek().variants) {
            isMatch = attributeCombination.every(({ option }, attrIndex) => {
                const variantAttribute = variant.attributes[attrIndex];
                return option === variantAttribute.option;
            });

            if (isMatch) return true;
        }

        /* console.log(`${option}: false`) */
        return false;
    }

    const displayOptions = (attr, attr_index) => {
        const optionItems = [];
        for (const opt_index in attr.options) {
            const opt = attr.options[opt_index];
            if (!selectedAttributes.value[attr_index]) {
                continue;
            }

            const optionValue = typeof opt === "string" ? opt : opt.name
            const selected_id = selectedAttributes.value[attr_index].id;
            const selected_option = selectedAttributes.value[attr_index].option;
            const isSelected = attr.id === selected_id && optionValue === selected_option;
            const typeVariable = product.peek().type === "variable";
            const typeSimple = product.peek().type === "simple";
            const selectable = typeVariable ? isSelectable(attr.name, optionValue) : true;

            if (opt.attribute_type === "color") {
                optionItems.push(
                    <div key={opt_index} className={`${!selectable && "cursor-not-allowed"}`}>
                        <Button
                            disabled={!selectable}
                            style={{ background: opt.primary_color }}
                            onClick={() => setSelectedAttributes(attr.name, optionValue)}
                            className={`
                                transition-all ${typeVariable && "hover:scale-110"} outline outline-2 outline-offset-2
                                ${isSelected || typeSimple ? "outline-blue-300" : "outline-zinc-300"}
                                shadow-md rounded-full w-8 h-8 relative mr-1
                            `}
                        >
                            {!selectable && (
                                <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center'>
                                    <X className='text-red-600 scale-[1.8]' />
                                </div>
                            )}
                        </Button>
                    </div>

                )
                continue;
            }

            optionItems.push(
                <div key={opt_index} className={`${!selectable && "cursor-not-allowed"}`}>
                    <Button
                        disabled={!selectable}
                        onClick={() => setSelectedAttributes(attr.name, optionValue)}
                        className={`px-2 py-0 h-8 shadow-md border-2 transition-all relative
                            ${isSelected || typeSimple ? "bg-white hover:bg-white text-black border-2 border-blue-300" :
                                "border-transparent hover:bg-primary"
                            }
                            ${typeVariable && "hover:scale-110"}
                        `}
                    >
                        {optionValue}
                        {!selectable && (
                            <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center '>
                                <X className='text-red-600 scale-[1.8]' />
                            </div>
                        )}
                    </Button>
                </div>
            );
        }

        return optionItems;
    }

    const displayAttributes = () => (
        <div className='flex flex-col justify-start items-start gap-2'>
            {
                product.value.attributes.map((attr, attr_index) => {
                    const options = displayOptions(attr, attr_index);
                    if (options.length === 0) return (<Fragment key={attr_index} />);

                    return (
                        <div key={attr_index} className="w-full h-fit flex justify-center items-start flex-col gap-2">
                            <h3 className="font-extrabold text-base text-black first-letter:capitalize">{attr.name}</h3>
                            <div className="flex justify-start items-center gap-2">
                                {options}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )

    useEffect(() => {
        selectedAttributes.value = product.peek().selected_attributes.reduce((acc, attr, index) => {
            acc[index] = { id: attr.id, name: attr.name, option: attr.option }
            return acc;
        }, [])

        //console.log(selectedAttributes.peek())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (selectedAttributes.value.length === 0) return (<></>)

    return (
        <>
            {displayAttributes()}
        </>
    )
}

export default ProductAttributes;