//import { Separator } from '@components/ui/separator'
import { effect } from '@preact/signals-react';


import Background from '@components/home/Background';
import Descriptions from '@components/home/Descriptions';
import HomeCategories from '@components/home/HomeCategories';
import Gallery from '@components/home/Gallery';
import Reviews from '@components/home/Reviews';

export default function Home() {

    effect(() => {
        window.scrollTo(0, 0);
    })


    return (
        <>
            <Background />
            <HomeCategories />
            <Descriptions />
            <Gallery />
            <Reviews />
        </>
    )
}