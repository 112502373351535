import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCompactDisc } from "@fortawesome/free-solid-svg-icons"
import { motion, AnimatePresence } from 'framer-motion';

import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LoadingPage } from '@app';

const animateCircle = {
    hidden: {
        opacity: 0,
        transition: { duration: .2 }
    },
    visible: {
        opacity: 1,
        transition: { duration: .2 }
    },
    exit: {
        opacity: 0,
        transition: { duration: .2 }
    }
}

const LoadingCircle = () => {
    const location = useLocation()
    const { isLoading, setIsLoading } = useContext(LoadingPage);

    useEffect(() => {
        setIsLoading(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setIsLoading(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return (
        <AnimatePresence>
            {isLoading && (
                <motion.div
                    variants={animateCircle}
                    initial='hidden'
                    animate='visible'
                    exit='exit'
                    className='sticky bg-[#FCF8F3] top-0 left-0 z-[300] h-screen w-screen flex justify-center items-center pointer-events-none text-[#635B55]'
                >
                    <div className="w-[200px] h-[200px] text-[200px] relative flex justify-center items-center">
                        {/* <FontAwesomeIcon className="animate-spin relative " icon={faCircleNotch} /> */}
                        <motion.div className="w-fit h-fit top-50 left-50 absolute flex items-center justify-center">
                            <FontAwesomeIcon className="animate-spin-slow" icon={faCompactDisc} />
                        </motion.div>
                    </div>
                </motion.div>
            )}

        </AnimatePresence>
    )
}

export default LoadingCircle;